import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  RightOutlined,
  HourglassOutlined,
  DollarOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, message, Modal, Tooltip, Dropdown } from 'antd'
import { Formik, Form } from 'formik'
import { Select, Input } from 'formik-antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import React, { useContext, useState, useRef } from 'react'
import { useInfiniteQuery, useQuery, queryCache } from 'react-query'
import styled from 'styled-components/macro'

import CampaignInvite from './CampaignInvite'
import { CreatorListItem } from './CreatorListItem'
import { ActionMenuIcon } from '../../../components/general/ActionMenuIcon'
import { SavingIndicator } from '../../../components/general/SavingIndicator'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import { ActionMenuWrapper } from '../../../GlobalStyles'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

const Skeleton = () => (
  <div className='skeleton'>
    <div className='section'>
      <div className='skelement' />
      <div className='skelement' />
    </div>
    <div className='section'>
      <div className='skelement' />
    </div>
  </div>
)

export const CreatorList = ({ list, formData }) => {
  const flags = useFlags()
  const { fetchCreatorListItems, brandId, fetchCampaigns, updateCreatorList, archiveCreatorList } =
    useContext(BrandContext)
  const [expanded, setExpanded] = useState(false)
  const scrollRef = useRef()
  const submitRef = useRef(0)
  const [savingStatus, setSavingStatus] = useState(undefined)
  const [archiving, setArchiving] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [archiveListModalVisible, setArchiveListModalVisible] = useState(false)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { data, status, isFetching, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
    ['creator-list-items', { brandId, creatorListId: list.id, formData, expanded }],
    // only fetch creators if list is expanded for better performance
    expanded
      ? fetchCreatorListItems
      : () => {
          return { creatorListItems: [], nextPage: null }
        },
    {
      getFetchMore: lastPage => lastPage.nextPage,
    }
  )

  const { data: campaigns } = useQuery(['campaigns', brandId], fetchCampaigns)

  const handleScroll = () => {
    const scrollDistance = scrollRef.current.scrollTop
    const outerHeight = scrollRef.current.offsetHeight
    const innerHeight = scrollRef.current.scrollHeight
    const actualDistance = innerHeight - (scrollDistance + outerHeight)
    if (actualDistance < 100 && canFetchMore && !isFetching) {
      fetchMore()
    }
  }

  const handleUpdate = async data => {
    setSavingStatus('submitting')
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      if (thisSubmit === submitRef.current) {
        const result = await updateCreatorList(list.id, data)
        queryCache.invalidateQueries('creator-lists')
        queryCache.invalidateQueries('paginated-creator-lists')
        if (result.error) {
          setSavingStatus('error')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        } else {
          setSavingStatus('success')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        }
      }
    }, 300)
  }

  const handleArchive = async () => {
    setArchiving(true)
    const result = await archiveCreatorList(list.id)
    queryCache.invalidateQueries('creator-list-items', list.id)
    queryCache.invalidateQueries('archived-creators', brandId)
    queryCache.invalidateQueries('creator-lists', brandId)
    queryCache.invalidateQueries('paginated-creator-lists', brandId)
    if (result.status === 200) {
      message.success('List has been archived.')
      setArchiveListModalVisible(false)
      setArchiving(false)
    } else {
      message.error('Oops, something went wrong.')
      setArchiving(false)
    }
  }

  const handleInviteAll = async e => {
    e.stopPropagation()
    setInviteModalOpen(true)
  }

  const handleExport = e => {
    e.stopPropagation()
    setDownloading(true)
    fetch(`${NODE_URL}/creator-list-export/${list.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    }).then(async response => {
      let blob = await response.blob()
      const fileName = response.headers.get('file-name')
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      setDownloading(false)
    })
  }

  const initialValues = {
    title: list?.title,
    campaignId: list?.campaignId,
  }

  return (
    <Wrapper key={list.id}>
      <Formik initialValues={initialValues} onSubmit={handleUpdate}>
        {({ submitForm }) => (
          <Form
            className={`creator-list ${expanded && 'expanded'}`}
            onClick={() => setExpanded(!expanded)}>
            <div className={`expand-list ${expanded && 'expanded'}`}>
              <RightOutlined />
            </div>
            <div className='inputs'>
              <Input
                name='title'
                placeholder='List title'
                required
                min={4}
                suffix={<EditOutlined />}
                style={{ width: '100%', maxWidth: '300px' }}
                onChange={submitForm}
                onClick={e => e.stopPropagation()}
              />
              <Select
                name='campaignId'
                placeholder='Campaign'
                allowClear
                style={{ width: '100%', maxWidth: '250px' }}
                onChange={submitForm}
                onClick={e => e.stopPropagation()}>
                {campaigns?.map(campaign => (
                  <Option key={campaign.id} value={campaign.id}>
                    {campaign.socialChannels?.map((channel, i) => (
                      <img
                        src={socialIcons[channel]}
                        alt={channel}
                        key={i}
                        style={{ height: '17px', marginRight: '5px' }}
                      />
                    ))}
                    {campaign.maxPaidAmount && (
                      <span
                        style={{
                          color: '#027df0',
                          marginRight: '5px',
                        }}>
                        <DollarOutlined />
                      </span>
                    )}
                    {campaign.title}
                  </Option>
                ))}
              </Select>
              <p className='num-profiles'>
                <UserOutlined /> {list.creatorCount}
              </p>
              {!list.contactsReady && (
                <span className='contacts-not-ready'>
                  <Tooltip title='Contacts are being fetched. This can take a few minutes.'>
                    <HourglassOutlined />
                  </Tooltip>
                </span>
              )}
            </div>

            <div className='actions'>
              <SavingIndicator savingStatus={savingStatus} />
              <p className='date'>{moment(list.created).format('ll')}</p>
              <Dropdown
                overlay={
                  <ActionMenuWrapper>
                    {flags.bulkEmailBeta && (
                      <Button type='link' icon={<MailOutlined />} onClick={handleInviteAll}>
                        Invite All
                      </Button>
                    )}
                    <Button
                      type='link'
                      onClick={handleExport}
                      icon={<DownloadOutlined />}
                      loading={downloading}>
                      Export List
                    </Button>
                    <Button
                      type='link'
                      danger
                      icon={<DeleteOutlined />}
                      onClick={e => {
                        e.stopPropagation()
                        setArchiveListModalVisible(true)
                      }}>
                      Delete List
                    </Button>
                  </ActionMenuWrapper>
                }
                onClick={e => e.stopPropagation()}
                onOpenChange={() => setDropdownOpen(!dropdownOpen)}>
                <ActionMenuIcon open={dropdownOpen} />
              </Dropdown>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={archiveListModalVisible}
        destroyOnClose
        onCancel={() => setArchiveListModalVisible(false)}
        title='Delete list'
        footer={false}>
        <p>
          Are you sure you want to delete this list? All creators in this list will be moved to the
          archive and cannot be recovered.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            marginTop: '20px',
          }}>
          <Button type='secondary' onClick={() => setArchiveListModalVisible(false)}>
            Cancel
          </Button>
          <Button type='danger' onClick={handleArchive} loading={archiving}>
            Delete
          </Button>
        </div>
      </Modal>
      <Modal
        title='Invite to campaign'
        open={inviteModalOpen}
        destroyOnClose
        onCancel={() => setInviteModalOpen(false)}
        footer={null}>
        <CampaignInvite
          creatorListId={list.id}
          creatorListTitle={list.title}
          selectedCampaignId={list.campaignId}
          close={() => setInviteModalOpen(false)}
        />
      </Modal>
      {expanded && (
        <div
          className={`creators ${expanded && 'expanded'}`}
          onScroll={handleScroll}
          ref={scrollRef}>
          {status === 'success' &&
            (list.creatorCount > 0 ? (
              <>
                {data.map(group =>
                  group.creatorListItems.map(creator => (
                    <CreatorListItem
                      creator={creator}
                      key={creator.id}
                      campaigns={campaigns}
                      selectedCampaignId={list?.campaignId}
                      creatorListId={list.id}
                    />
                  ))
                )}
                {isFetchingMore && <Skeleton />}
              </>
            ) : (
              <p className='no-results'>No results.</p>
            ))}

          {status === 'loading' && Array.from(Array(10).keys()).map(i => <Skeleton key={i} />)}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e6e6e6;
  overflow: hidden;
  .creator-list {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px;
    z-index: 1;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #f6f6f6;
    }
    .inputs {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .expand-list {
      display: flex;
      align-items: center;
      color: ${props => props.theme.crcoTechBlue};
      cursor: pointer;
      font-size: 0.8rem;
      .anticon {
        transition: 0.2s ease-in-out;
      }
      &.disabled {
        pointer-events: none;
        color: #ccc;
      }
      &.expanded {
        .anticon {
          transform: rotate(90deg);
        }
      }
    }
    .num-profiles {
      margin: 0;
      font-size: 0.8rem;
      color: ${props => props.theme.crcoGrey};
    }
    .contacts-not-ready {
      display: grid;
      place-content: center;
      padding: 5px;
      background: orange;
      color: #fff;
      border-radius: 50%;
    }
    .actions {
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
  .creators {
    background: #f6f6f6;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 20px;
    border-radius: 0 0 5px 5px;
    transition: 0.3s ease-in-out;
    &.expanded {
      max-height: 600px;
      overflow: auto;
      padding: 20px;
      ${props => props.theme.scrollbar}
    }
    .loading {
      display: grid;
      place-content: center;
    }
  }
  .label,
  .date {
    margin: 0;
    font-size: 0.8rem;
    font-family: 'Campton-Light', Verdana, sans-serif;
    white-space: nowrap;
  }
  .no-results {
    display: grid;
    place-content: center;
    margin: 10px;
    opacity: 0.5;
  }
  .skeleton {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    gap: 12px;
    min-height: 120px;
    .section {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .skelement {
        flex: 1;
        border-radius: 3px;
        background-image: linear-gradient(90deg, #fafafa 0px, #ffffff 100px, #fafafa 300px);
        background-size: 100vw 100%;
        animation: shine 1.5s infinite ease-in-out;
      }
    }
  }

  @keyframes shine {
    0% {
      background-position-x: -20vw;
    }
    95%,
    100% {
      background-position-x: 85vw;
    }
  }
`
