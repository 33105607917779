import axios from 'axios'
import React, { createContext, useState, useContext } from 'react'
import { queryCache } from 'react-query'

import { BrandContext } from './BrandContext'
import { NODE_URL } from '../constants'

export const CampaignContext = createContext(null)

export const CampaignContextProvider = props => {
  const { brandId } = useContext(BrandContext)
  const [selectedCampaignID, setSelectedCampaignID] = useState(null)

  const fetchCampaign = async (key, campaignID) => {
    if (campaignID) {
      const { data } = await axios.get(`${NODE_URL}/brand-campaign/${campaignID}`)
      return data
    } else return null
  }

  const fetchOptIns = async (key, query, page) => {
    const { data } = await axios.get(`${NODE_URL}/opt-ins/${query.campaignId}/${page ? page : 1}`, {
      params: query.formData, // this sets the url params
    })

    return data
  }

  // perform the same function for seen filter
  const fetchUnseenOptIns = async (key, campaignID, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/opt-ins/${campaignID}/${page}`, {
      params: {
        unseen: true,
        status: ['pending'],
      },
    })

    return data
  }

  const fetchShoutouts = async (key, { campaignID, formData }, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/${campaignID}/shoutouts/${page}`, {
      params: {
        ...formData,
      },
    })

    return data
  }

  const exportShoutouts = async (brandId, campaignID) => {
    const data = await fetch(
      `${NODE_URL}/brand/${brandId}/campaign/${campaignID}/shoutouts/export`,
      {
        method: 'POST',
      }
    )

    return data
  }

  const fetchStories = async (key, { campaignID }, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/stories/${campaignID}/${page}`, {})

    return data
  }

  const exportStories = async (brandId, campaignID) => {
    const data = await fetch(`${NODE_URL}/brand/${brandId}/campaign/${campaignID}/stories/export`, {
      method: 'POST',
    })

    return data
  }

  const fetchUploads = async (key, { campaignID, formData }, page = 1) => {
    const { data } = await axios.get(
      `${NODE_URL}/brand/${brandId}/campaign/${campaignID}/uploads`,
      {
        params: {
          page,
          ...formData,
        },
      }
    )

    return data
  }

  const fetchContentPreviews = async (key, { campaignID, formData, sort }, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/content-previews/${campaignID}/${page}`, {
      params: {
        sort,
        ...formData,
      },
    })

    return data
  }

  // contains analytics about influencer, shoutouts, and all shoutouts items
  const fetchMetrics = async (key, campaignId, params) => {
    const { data } = await axios.get(
      `${NODE_URL}/brand/${brandId}/campaign/${campaignId}/metrics`,
      {
        params,
      }
    )

    return data
  }

  // create a new campaign with data copied from existing campaign
  const duplicateCampaign = async (campaignID, values) => {
    const result = await axios.post(`${NODE_URL}/campaigns/duplicate/${campaignID}`, {
      duplicateCampaignName: values.title,
      duplicateCampaignSlug: values.slug,
    })

    return result
  }

  // set campaign as paused
  const pauseCampaign = async campaignId => {
    return axios
      .put(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/status`, { status: 'paused' })
      .then(() => {
        queryCache.invalidateQueries(['campaigns', brandId])
        return true
      })
      .catch(() => {
        return { success: false, error: 'Sorry, something went wrong.' }
      })
  }

  //set campaign as archived
  const archiveCampaign = async campaignId => {
    return axios
      .put(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/status`, { status: 'archived' })
      .then(() => {
        queryCache.invalidateQueries(['campaigns', brandId])
        return true
      })
      .catch(() => {
        return { success: false, error: 'Sorry, something went wrong.' }
      })
  }

  //restore campaign from archived to drafts
  const restoreCampaign = async campaignId => {
    return axios
      .put(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/status`, { status: 'draft' })
      .then(() => {
        queryCache.invalidateQueries(['campaigns', brandId])
        return true
      })
      .catch(() => {
        return { success: false, error: 'Sorry, something went wrong.' }
      })
  }

  const shareCampaign = async (campaignId, data) => {
    const emails = data.shareCampaigns.map(campaign => campaign.value)

    return axios
      .post(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/share-campaign`, {
        emails,
      })
      .then(() => {
        queryCache.invalidateQueries(['campaigns', brandId])
        return true
      })
      .catch(() => {
        return { success: false, error: 'Sorry something went wrong.' }
      })
  }

  const campaignContextValues = {
    selectedCampaignID,
    brandId,
    setSelectedCampaignID,
    fetchCampaign,
    fetchOptIns,
    fetchUnseenOptIns,
    fetchShoutouts,
    exportShoutouts,
    fetchStories,
    exportStories,
    fetchUploads,
    fetchContentPreviews,
    fetchMetrics,
    duplicateCampaign,
    pauseCampaign,
    archiveCampaign,
    restoreCampaign,
    shareCampaign,
  }

  return (
    <CampaignContext.Provider value={campaignContextValues}>
      {props.children}
    </CampaignContext.Provider>
  )
}
