import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { Button, Tooltip, message, notification, Alert } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, InputNumber } from 'formik-antd'
import React, { useState, useContext } from 'react'
import { useQuery, queryCache } from 'react-query'
import * as Yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

const BrandSmtpInfo = () => {
  const [editMode, setEditMode] = useState(false)
  const [testEmailLoading, setTestEmailLoading] = useState(false)

  const { fetchCurrentUserData } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData)

  const smtpState = brandData?.extraData?.smtpState

  const formSchema = Yup.object().shape({
    host: Yup.string().required('Required'),
    emailAddress: Yup.string().email('Must be a valid email').required('Required'),
    user: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    replyToAddress: Yup.string().email('Must be a valid email').required('Required'),
    senderName: Yup.string().required('Required'),
    portNumber: Yup.number().required('Required').positive('Must be a positive number'),
  })

  const initialValues = {
    host: brandData?.extraData?.smtpCredentials?.host,
    emailAddress: brandData?.extraData?.smtpCredentials?.emailAddress,
    user: brandData?.extraData?.smtpCredentials?.user,
    password: brandData?.extraData?.smtpCredentials?.password,
    replyToAddress: brandData?.extraData?.smtpCredentials?.replyToAddress,
    senderName: brandData?.extraData?.smtpCredentials?.senderName,
    portNumber: brandData?.extraData?.smtpCredentials?.portNumber,
  }

  const handleTestEmailButton = (values, email) => {
    axios
      .post(`${NODE_URL}/brand/${brandId}/email/smtp/test`, { ...values, testEmail: email })
      .then(() => {
        notification.success({
          message: 'Successful connection',
          description: `Please check ${email} to see the test notification.`,
        })
      })
      .catch(() => {
        notification.error({
          message: 'Invalid SMTP Credentials',
          description:
            'An error occured when sending a test notification. Please double check your credentials and try again.',
          duration: 0,
        })
      })
      .finally(() => {
        setEditMode(false)
        setTestEmailLoading(false)
      })
  }

  const handleSubmit = async data => {
    try {
      await axios.post(`${NODE_URL}/brand/${brandId}/email/smtp/save`, data)
      message.success('SMTP Settings successfully updated!')
      queryCache.invalidateQueries('brand')
    } catch (err) {
      message.error(`Failed to save SMTP settings! ${err.err || ''}`)
    } finally {
      setEditMode(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, values, errors }) => (
        <Form className='account-section' id='smtp-settings'>
          <div className='section-header'>
            <h2 className='section-title'>
              SMTP Settings{' '}
              <Tooltip
                className='tooltip-icon'
                arrow
                title={`An SMTP connection lets you send emails from our platform using your own email system. This helps to personalize your outreach and improve your email deliverability.`}>
                <QuestionCircleOutlined />
              </Tooltip>
            </h2>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {!editMode ? (
                <>
                  <Button
                    className='test-button'
                    loading={testEmailLoading}
                    onClick={() => {
                      handleTestEmailButton(values, userData.email)
                      setTestEmailLoading(true)
                    }}
                    disabled={Object.keys(errors).length !== 0 || !values.host}
                    type='link'>
                    <MailOutlined />
                    Send Test Email
                  </Button>
                  <Button type='link' onClick={() => setEditMode(true)}>
                    <EditOutlined /> Edit
                  </Button>
                </>
              ) : (
                <div className='buttons'>
                  <Button danger type='link' onClick={() => setEditMode(false)}>
                    <CloseCircleOutlined /> Cancel
                  </Button>
                  <Button
                    type='link'
                    onClick={submitForm}
                    loading={isSubmitting}
                    disabled={Object.keys(errors).length !== 0}>
                    <SaveOutlined /> Save
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className='section-body'>
            <div className='form-row-wrapper'>
              <FormItem
                name='host'
                label='SMTP Server'
                info='Your email provider SMTP hostname'
                width={'100%'}>
                <Input name='host' placeholder='e.g. smtp.relay.com' disabled={!editMode} />
              </FormItem>

              <FormItem
                name='replyToAddress'
                label='Reply to Address'
                info='The email address you want to receive replies to.'
                width={'100%'}>
                <Input
                  name='replyToAddress'
                  placeholder='e.g. creatorco@gmail.com'
                  disabled={!editMode}
                />
              </FormItem>
            </div>

            <div className='form-row-wrapper'>
              <FormItem
                name='user'
                label='User'
                info='The username/email address you use to sign in to your email account.'
                width={'100%'}>
                <Input name='user' placeholder='e.g. creatorco@gmail.com' disabled={!editMode} />
              </FormItem>

              <FormItem
                name='password'
                label='Password'
                info='The password you use to sign in to your email account or the app specific password from your email settings.'
                width={'100%'}>
                <Input.Password
                  name='password'
                  placeholder='*********'
                  disabled={!editMode}
                  autoComplete='new-password'
                />
              </FormItem>
            </div>

            <div className='form-row-wrapper'>
              <FormItem
                name='senderName'
                label='Sender Name'
                info='The name/title you want to display as sender’s name.'
                width={'100%'}>
                <Input name='senderName' placeholder='e.g. John Doe' disabled={!editMode} />
              </FormItem>

              <FormItem
                name='emailAddress'
                label='Sender Email Address'
                info='The email address that will be shown as sender email.'
                width={'100%'}>
                <Input
                  name='emailAddress'
                  placeholder='e.g. creatorco@gmail.com'
                  disabled={!editMode}
                />
              </FormItem>
            </div>

            <FormItem
              name='portNumber'
              label='Port Number'
              // info='The port of your SMTP server. With SSL/TLS it could be one of 25, 465, 587 and 2526 (Elastic Email).'
              info='The port of your SMTP server.'>
              <InputNumber
                name='portNumber'
                placeholder='e.g. 25'
                controls={false}
                disabled={!editMode}
                style={{ maxWidth: '49%' }}
              />
            </FormItem>
            {smtpState?.healthy === false && (
              <div className='form-row-wrapper'>
                <Alert
                  style={{ width: '100%' }}
                  message={smtpState?.error || 'Unknown SMTP connection error!'}
                  type='error'
                />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}
export default BrandSmtpInfo
