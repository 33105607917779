import {
  SendOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  PaperClipOutlined,
  CopyOutlined,
  PlusOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  ArrowRightOutlined,
  CaretRightOutlined,
  PhoneOutlined,
  MailOutlined,
  DesktopOutlined,
} from '@ant-design/icons'
import {
  Button,
  Modal,
  Upload,
  Drawer,
  Collapse,
  Empty,
  Spin,
  notification,
  Tooltip,
  message,
} from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import moment from 'moment'
import React, { useContext, useState, useEffect, useRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery, useInfiniteQuery, queryCache } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { CreatorProfileActions } from '../../components/campaign-dashboard/influencer-profile/CreatorProfileActions'
import OptInProfile from '../../components/campaign-dashboard/influencer-profile/OptInProfile'
import { CreateTemplateModal } from '../../components/forms/ChatTemplate'
import FormItem from '../../components/forms/FormItem'
import CreatorAvatar from '../../components/general/CreatorAvatar'
import { NODE_URL } from '../../constants'
import { InfluencerContextProvider } from '../../contexts/InfluencerContext'
import { OptInContextProvider } from '../../contexts/OptInContext'
import { UserContext } from '../../contexts/UserContext'
import { socket } from '../../service/socket'
import { getProfilePicUrl, extractFileNameFromUrl } from '../../utils'

export const Chat = ({ drawer, optInId }) => {
  // #region Variables
  const { fetchCurrentUserData } = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [isChangingMessageReadStatus, setIsChangingMessageReadStatus] = useState(false)
  const [creator, setCreator] = useState()
  const [messages, setMessages] = useState()
  const [lastCreatorMessage, setLastCreatorMessage] = useState()
  const [unread, setUnread] = useState(undefined)
  const [socketId, setSocketId] = useState(false)
  const [fileList, setFileList] = useState([])
  const [chatTemplateModal, setChatTemplateModal] = useState(false)
  const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)
  const [optIn, setOptIn] = useState()
  const [profilePicUrl, setProfilePicUrl] = useState(undefined)
  const [creatorInitials, setCreatorInitials] = useState(undefined)

  const { Panel } = Collapse

  const scrollRef = useRef()
  // #endregion Variables

  // #region Queries
  const { data: userData, status } = useQuery('current-user', fetchCurrentUserData)

  const {
    data: chatData,
    status: chatStatus,
    fetchMore,
    canFetchMore,
    isFetching,
  } = useInfiniteQuery(
    ['chat', optInId],
    async (key, query, page = 1) => {
      const { data } = await axios.get(`${NODE_URL}/chat/${optInId}/messages/brand/${page}`)
      return data
    },
    {
      getFetchMore: lastPage => lastPage.nextCursor,
    }
  )
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    // This effect sets the socket ID, listens for new message and server connection events on the socket, and cleans up these listeners when the component unmounts or the dependencies change, triggering a refetch of chat data when a new relevant message arrives
    setSocketId(socket.id)

    socket.on(`contains-restricted-words-${optInId}`, data => {
      if (data) {
        // If message contains restricted words -> let user know that the full message was not sent
        notification.warning({
          message: 'Restricted Content Detected!',
          description:
            'Your recent message wasn’t sent via SMS due to content restrictions. The creator will be prompted to view your message on their dashboard instead.',
          duration: 0,
        })
      }
    })

    socket.on('new-message', chatOptInId => {
      if (chatOptInId === optInId) queryCache.invalidateQueries('chat')
    })

    socket.on('SERVER_CONNECT')

    return () => {
      socket.off('message')
      socket.off('SERVER_CONNECT')
    }
  }, [optInId, setSocketId])

  useEffect(() => {
    if (!chatData) return

    setMessages(
      chatData
        .map(group => group.messages.flat())
        .flat()
        .reverse()
    )

    setCreator(chatData[0]?.creator)
    setOptIn(chatData[0]?.optIn)

    // Invalidate queries to remove unread message indicators
    queryCache.invalidateQueries('opt-ins')
    queryCache.invalidateQueries('campaigns')
  }, [chatData])

  useEffect(() => {
    // Get last message from creator for admins to mark unread if needed
    if (!messages) return

    const creatorMessages = messages.filter(msg => msg.sender === 'creator')

    if (creatorMessages?.length) setLastCreatorMessage(creatorMessages[creatorMessages.length - 1])

    setUnread(creatorMessages.filter(msg => msg.read === false).length)

    setIsChangingMessageReadStatus(false)

    // Check for set loading when first message still being sent
    if (messages?.find(m => !!m.sending) && messages.length == 1) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [messages])

  useEffect(() => {
    if (!optIn) return

    const { user } = optIn
    const url = getProfilePicUrl(optIn)
    const initials = `${user.firstName?.charAt(0).toUpperCase()} ${user.lastName?.charAt(0).toUpperCase()}`

    setProfilePicUrl(url)
    setCreatorInitials(initials)
  }, [optInId, optIn])
  // #endregion Effects

  // #region Functions
  const markRead = async () => {
    // (admins only) mark all messages as read
    setIsChangingMessageReadStatus(true)
    await axios
      .put(`${NODE_URL}/admin/opt-in/${optInId}/messages`)
      .then(() => {
        // invalidate queries to remove unread message indicators
        queryCache.invalidateQueries('chat')
        queryCache.invalidateQueries('opt-ins')
        queryCache.invalidateQueries('campaigns')
      })
      .catch(() => {
        message.error('Error marking messages as read')
      })
  }

  const markUnread = async () => {
    // (admins only) mark the last message from creator as unread
    setIsChangingMessageReadStatus(true)
    await axios
      .put(`${NODE_URL}/admin/opt-in/chat/message/${lastCreatorMessage.id}`)
      .then(() => {
        // invalidate queries to add unread message indicators
        queryCache.invalidateQueries('chat')
        queryCache.invalidateQueries('opt-ins')
        queryCache.invalidateQueries('campaigns')
      })
      .catch(() => {
        message.error('Error marking message as unread')
      })
  }

  const newMessage = message => {
    const chatObj = {
      content: message,
      sender: 'brand',
      sending: true,
      user: userData,
      read: false,
    }
    setMessages(prev => (prev ? [...prev, chatObj] : [chatObj]))

    scrollRef.current.scrollTop = 0
  }

  const handleRegex = e => {
    function interpolateTemplate(template, interpolations) {
      return template.replace(/\{\{\s*([^}\s]+)\s*\}\}/g, (_, token) => interpolations[token])
    }
    const message = interpolateTemplate(e, {
      firstName: creator?.firstName || 'Creator',
      lastName: creator?.lastName || '',
      fullName: `${creator?.firstName || 'Creator'} ${creator?.lastName || ''} `,
      email: creator?.email || 'your email',
      phone: `+${creator?.phoneCode || '1'} ${creator?.phone || ' and your phone number'}`,
      brandName: chatData[0].brand.name || 'the brand',
      campaignName: chatData[0].campaign.title || 'this campaign.',
    })
    return message.replace(/\\'/g, "'")
  }

  const handleSubmit = async message => {
    if (!message) return
    newMessage(message)
    socket.emit(
      'send-message',
      { message, optInId, userId: userData.id, sender: 'brand' },
      socketId
    )
  }

  const handleUpload = async fileData => {
    const uid = fileData.file.uid
    const maxFileSize = fileData.file.type.includes('image') ? 10000000 : 100000000

    if (fileData.file.size <= maxFileSize) {
      setFileList(prev => [
        ...prev,
        {
          uid,
          name: fileData.file.name,
          percent: 0,
          status: 'uploading',
        },
      ])

      const formData = new FormData()
      formData.append('upload', fileData.file)

      // handle upload
      axios
        .post(`${NODE_URL}/chat-file-upload/${optInId}?sender=brand`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: data => {
            const percent = Math.round((100 * data.loaded) / data.total)
            // update progress of given upload
            setFileList(prev =>
              prev.map(file =>
                file.uid === uid
                  ? {
                      ...file,
                      percent,
                    }
                  : file
              )
            )
          },
        })
        .then(() => {
          setFileList(prev =>
            prev.map(file =>
              file.uid === uid
                ? {
                    ...file,
                    status: 'done',
                  }
                : file
            )
          )
          queryCache.invalidateQueries('chat')

          scrollRef.current.scrollTop = 0

          setTimeout(() => {
            setFileList(prev => prev.filter(file => file.uid !== uid))
          }, 3000)
        })
        .catch(() => {
          setFileList(prev =>
            prev.map(file =>
              file.uid === uid
                ? {
                    ...file,
                    status: 'error',
                  }
                : file
            )
          )
        })
    } else {
      setFileList(prev => [
        ...prev,
        {
          uid,
          name: `File too large (max ${maxFileSize / 1000000}MB)`,
          status: 'error',
        },
      ])

      setTimeout(() => {
        setFileList(prev => prev.filter(file => file.uid !== uid))
      }, 5000)
    }
  }

  const handleScroll = () => {
    // fetch more messages when scrolled to top
    const scrollDistance = scrollRef.current.scrollTop
    const outerHeight = scrollRef.current.offsetHeight
    const innerHeight = scrollRef.current.scrollHeight
    const actualDistance = innerHeight + (scrollDistance - outerHeight)
    if (actualDistance < 200 && canFetchMore && !isFetching) {
      fetchMore()
    }
  }

  // #endregion Functions

  const MessageContent = ({ message }) => {
    let content
    if (message.type === 'image') {
      const fileName = extractFileNameFromUrl(message.content)
      content = (
        <a className='file-download' download={true} href={message.content}>
          <img src={message.content} alt={fileName} />
        </a>
      )
    } else if (message.type === 'file') {
      const fileName = extractFileNameFromUrl(message.content)
      content = (
        <a className='file-download' download={true} href={message.content} alt={fileName}>
          {fileName}
        </a>
      )
    } else if (message.type === 'notification') {
      content = (
        <span>
          <ExclamationCircleFilled /> {message.content}
        </span>
      )
    } else {
      content = message.content
    }

    return (
      <Text
        className={message.type}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: message.sender === 'creator' ? 'flex-start' : 'flex-end',
        }}>
        {(message.type === 'image' || message.type === 'file') && (
          <span className='info-text'>
            <PaperClipOutlined /> Attachment (click to download)
          </span>
        )}
        {content}
      </Text>
    )
  }

  const smsStatus =
    chatData && chatData[0]?.chat?.conversationId ? (
      <div>SMS Connected</div>
    ) : chatData && chatData[0]?.campaign?.metaData.disableTwilio ? (
      <Tooltip arrow title='Brand disabled SMS feature.'>
        <div>SMS Disabled</div>
      </Tooltip>
    ) : (
      <Tooltip arrow title='Creator’s phone number is from a country without SMS chat support.'>
        <div>SMS Unavailable</div>
      </Tooltip>
    )

  const formatMessageTime = created => {
    return created ? `${moment(new Date(created)).fromNow()} - ` : 'Date Unknown'
  }

  return (
    <Wrapper className='chat'>
      {chatStatus === 'success' && status === 'success' && chatData ? (
        <div className='inner'>
          {creator && !drawer && (
            // CHAT HEADER
            <div className='chat-header'>
              <div className='creator-info'>
                <div
                  className='avatar-wrapper'
                  onClick={() => setProfileOpen(true)}
                  onKeyDown={() => setProfileOpen(true)}
                  role='button'
                  tabIndex={0}>
                  <CreatorAvatar
                    className='avatar'
                    size={50}
                    id={creator.id}
                    url={profilePicUrl}
                    initials={creatorInitials}
                  />
                </div>
                <p className='creator-name'>
                  <span
                    className='profile-link'
                    onClick={() => setProfileOpen(true)}
                    onKeyDown={() => setProfileOpen(true)}
                    role='button'
                    tabIndex={0}>
                    {creator.firstName} {creator.lastName}
                  </span>
                  <br />
                  {optIn?.campaign?.socialChannels?.length > 1 &&
                    !!optIn?.extraData?.channels?.length && (
                      <span className='channels'>
                        (Opted-in to{' '}
                        {optIn?.extraData?.channels
                          .map(channel =>
                            channel === 'instagram'
                              ? 'Instagram'
                              : channel === 'tiktok'
                                ? 'TikTok'
                                : 'YouTube'
                          )
                          .join(', ')}
                        )
                      </span>
                    )}
                </p>
                <Link
                  className='opt-in-link'
                  to={`/campaigns/${chatData?.[0]?.campaign?.id}/opt-ins?search=${creator.email}`}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Button type='link'>
                    View Opt-In <ArrowRightOutlined />
                  </Button>
                </Link>
              </div>
              {userData.role === 'administrator' && (
                <div className='admin-bar'>
                  <span style={{ marginRight: '10px' }}>For admins:</span>
                  <div className='ids'>
                    <CopyToClipboard text={creator?.id}>
                      <div className='id'>User #{creator?.id}</div>
                    </CopyToClipboard>
                    |
                    <CopyToClipboard text={optInId}>
                      <div className='id'>Opt-in #{optInId}</div>
                    </CopyToClipboard>
                  </div>
                  {lastCreatorMessage && (
                    <div className='mark-read-status'>
                      {unread ? (
                        <Button onClick={markRead} type='link'>
                          Mark as read
                          {isChangingMessageReadStatus ? (
                            <LoadingOutlined spin />
                          ) : (
                            <CheckCircleFilled />
                          )}
                        </Button>
                      ) : (
                        <Button onClick={markUnread} type='link'>
                          Mark as unread
                          {isChangingMessageReadStatus ? (
                            <LoadingOutlined spin />
                          ) : (
                            <CheckCircleOutlined />
                          )}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {/* MESSAGES */}
          <Messages>
            <div className='wrapper' ref={scrollRef} onScroll={handleScroll}>
              <div className='inner'>
                <p className='info-text'>
                  This is the beginning of your message history with {creator?.firstName} for your{' '}
                  <b>{chatData?.[0]?.campaign?.title}</b> campaign.
                </p>
                {chatStatus === 'success' &&
                  creator &&
                  messages?.map((message, i) => {
                    // message sender is not creator (brand/admin), right aligned
                    if (message.sender !== 'creator') {
                      return (
                        <Message key={message.id || i} sender='current-user'>
                          <div className='message-info current-user'>
                            {message.sending ? (
                              <Status sender='current-user'>
                                <span className='sender-name'>
                                  {message?.userId
                                    ? message.user?.firstName
                                    : chatData[0]?.brand?.name}
                                  {message?.userId ? message.user?.lastName : ''}
                                </span>
                                <span>
                                  Sending <LoadingOutlined spin />
                                </span>
                              </Status>
                            ) : (
                              <Status sender='current-user'>
                                <span className='sender-name'>
                                  {`${
                                    message?.userId
                                      ? `${message.user?.firstName || ''} ${
                                          message.user?.lastName || ''
                                        }`
                                      : `${chatData[0]?.brand?.name}`
                                  }`}
                                </span>
                                <span>
                                  {message.messageSid !== null ? (
                                    <Tooltip title='Notified via SMS'>
                                      <span className='device'>
                                        <PhoneOutlined />
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title='Notified via email'>
                                      <span className='device'>
                                        <MailOutlined />
                                      </span>
                                    </Tooltip>
                                  )}
                                  {formatMessageTime(message?.created)}
                                  {message.read ? (
                                    <span className='read'>
                                      <b>Read</b> <CheckCircleFilled />
                                    </span>
                                  ) : (
                                    <span className='delivered'>
                                      Delivered <CheckCircleOutlined />
                                    </span>
                                  )}
                                </span>
                              </Status>
                            )}
                            {message?.userId && (
                              <CreatorAvatar
                                size={30}
                                id={message.user?.id}
                                color='#027df0'
                                initials={`${
                                  message?.userId
                                    ? `${message.user?.firstName?.charAt(0).toUpperCase() || ''} ${
                                        message.user?.lastName?.charAt(0).toUpperCase() || ''
                                      }`
                                    : ''
                                }`}
                              />
                            )}
                          </div>
                          <div className='message-content'>
                            <MessageContent message={message} />
                          </div>
                        </Message>
                      )
                    } else {
                      // message sender is creator, left aligned
                      return (
                        <Message key={message.id}>
                          <div className='message-info'>
                            <CreatorAvatar
                              size={30}
                              id={message.user?.id}
                              url={profilePicUrl}
                              initials={creatorInitials}
                            />
                            <Status>
                              <span className='sender-name'>
                                {message.user?.firstName} {message.user?.lastName}
                              </span>
                              <span>
                                <span>
                                  {message.messageSid !== null ? (
                                    <Tooltip title='Message sent via SMS'>
                                      <span className='device'>
                                        <PhoneOutlined />
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title='Message sent via browser'>
                                      <span className='device'>
                                        <DesktopOutlined />
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                                {formatMessageTime(message?.created)}
                                {message.read ? (
                                  <span className='read'>
                                    <b>Read</b> <CheckCircleFilled />
                                  </span>
                                ) : (
                                  <span className='delivered'>
                                    Received <CheckCircleOutlined />
                                  </span>
                                )}
                              </span>
                            </Status>
                          </div>
                          <div className='message-content'>
                            <MessageContent message={message} />
                          </div>
                        </Message>
                      )
                    }
                  })}
              </div>
            </div>
          </Messages>
          <div className='chat-options'>
            <div className='chat-actions'>
              <Button
                type='link'
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
                onClick={() => {
                  setChatTemplateModal(true)
                }}>
                <CopyOutlined /> Templates
              </Button>

              <div className='uploads'>
                <Upload
                  name='upload'
                  customRequest={handleUpload}
                  fileList={fileList}
                  accept='image/*,video/*,audio/*,.doc,.docx,.pdf,.csv,.xlsx,.txt'
                  showUploadList={{
                    showRemoveIcon: false,
                  }}>
                  <Button type='link' icon={<PaperClipOutlined />}>
                    Upload Files
                  </Button>
                </Upload>
              </div>
            </div>
            <div className='sms'>{smsStatus}</div>
          </div>
          <CreateTemplateModal
            brandId={chatData[0]?.brand?.id}
            createModalOpen={createTemplateModalOpen}
            setCreateModalOpen={setCreateTemplateModalOpen}
            chatId={chatData[0]?.chat?.id}
            campaign={chatData[0]?.campaign?.id}
          />
          <Formik
            initialValues={{ message: '' }}
            onSubmit={(data, { resetForm }) => {
              if (loading) return
              resetForm({ values: '' })
              handleSubmit(data.message)
            }}>
            {({ values, submitForm, setFieldValue }) => {
              const handlePressEnter = e => {
                if (!e.shiftKey) {
                  e.preventDefault()
                  submitForm(values)
                }
              }
              return (
                <Form id='message-form'>
                  <div className='inner'>
                    <div className='message'>
                      <FormItem name='message' size='small'>
                        <Spin spinning={loading} tip='Sending'>
                          <Input.TextArea
                            name='message'
                            maxLength={15000}
                            disabled={loading}
                            value={values.message || ''}
                            onPressEnter={handlePressEnter}
                            autoComplete='off'
                            allowClear
                            placeholder={`Message ${creator?.firstName}`}
                          />
                        </Spin>
                      </FormItem>
                      <Button htmlType='submit' disabled={!values.message || loading}>
                        <SendOutlined />
                      </Button>
                      <ChatTemplatesModal
                        // TODO: update me (use BulkMessageModal as a reference)
                        title='Chat Templates'
                        open={chatTemplateModal}
                        onCancel={() => setChatTemplateModal(false)}
                        footer={null}>
                        <ChatTemplatesWrapper>
                          <div className='chat-templates'>
                            {chatData && chatData[0]?.brand?.messageTemplate.length ? (
                              chatData[0]?.brand?.messageTemplate.map((template, i) => (
                                <div
                                  key={i}
                                  className='template'
                                  onClick={() => {
                                    setFieldValue('message', handleRegex(template.template))
                                    setChatTemplateModal(false)
                                  }}
                                  onKeyDown={() => {
                                    setFieldValue('message', handleRegex(template.template))
                                    setChatTemplateModal(false)
                                  }}
                                  role='button'
                                  tabIndex={0}>
                                  <div className='template-label'>{template.label}</div>
                                  <div className='template-name'>{template.template}</div>
                                </div>
                              ))
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description='No chat templates yet.'
                              />
                            )}
                          </div>
                          <AdminTemplatesCollapse
                            bordered={false}
                            accordion
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}>
                            {chatData &&
                              userData.role === 'administrator' &&
                              !!chatData[0]?.adminTemplates?.length && (
                                <Panel header={<div className='header'>Admin Templates</div>}>
                                  <div className='chat-templates'>
                                    {chatData[0]?.adminTemplates?.map((template, i) => (
                                      <div
                                        key={i}
                                        className='template'
                                        onClick={() => {
                                          setFieldValue('message', handleRegex(template.template))
                                          setChatTemplateModal(false)
                                        }}
                                        onKeyDown={() => {
                                          setFieldValue('message', handleRegex(template.template))
                                          setChatTemplateModal(false)
                                        }}
                                        role='button'
                                        tabIndex={0}>
                                        <div className='template-label'>{template.label}</div>
                                        <div className='template-name'>{template.template}</div>
                                      </div>
                                    ))}
                                  </div>
                                </Panel>
                              )}
                          </AdminTemplatesCollapse>

                          <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setChatTemplateModal(false)
                              setCreateTemplateModalOpen(true)
                            }}>
                            New Template
                          </Button>
                        </ChatTemplatesWrapper>
                      </ChatTemplatesModal>
                    </div>
                  </div>
                  <InfoText validInput={values.message}>
                    Press <b>Enter</b> to submit, or <b>Shift + Enter</b> to add a new line.
                  </InfoText>
                </Form>
              )
            }}
          </Formik>
        </div>
      ) : (
        <div className='loading'>
          <Spin />
        </div>
      )}

      {/* CREATOR PROFILE */}
      <InfluencerContextProvider>
        <OptInContextProvider>
          <Drawer
            title={`
            ${optIn?.status.charAt(0).toUpperCase() + optIn?.status.slice(1)}
            Opt-In: ${optIn?.user?.firstName} ${optIn?.user?.lastName}`}
            width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
            closable={true}
            onClose={() => {
              setProfileOpen(false)
            }}
            destroyOnClose={true}
            open={profileOpen}
            extra={<CreatorProfileActions optIn={optIn} />}>
            <OptInProfile
              key={optInId}
              optIn={optIn}
              close={() => {
                setProfileOpen(false)
              }}
            />
          </Drawer>
        </OptInContextProvider>
      </InfluencerContextProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: 'Campton-Medium', sans-serif;
  background: #d2e9f9;
  min-width: 400px;
  height: 100%;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 11;
  transition: 0.3s ease-in-out;
  position: relative;
  & > .inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }
  #message-form {
    display: block;
    .inner {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 8px;
      .overlay {
        transition: 0.2s;
        height: 10px;
        background-color: #e2f2ff5c;
        &.uploading {
          .uploading {
            opacity: 1;
          }
        }
        &.dragged {
          .dragged {
            opacity: 1;
          }
        }
        .uploading,
        .dragged {
          transition: 0.2s;
          border: 2px dashed grey;
          opacity: 0;
        }
        .uploading {
          opacity: 0.5;
        }
      }
      .message {
        position: relative;
      }
    }
  }
  .chat-header {
    padding: 10px 10px 0 10px;
    .creator-info {
      display: flex;
      align-items: center;
      .creator-name {
        margin: 0 10px;
        font-size: 0.9rem;
        span {
          color: rgba(0, 0, 0, 0.5);
        }
        .profile-link {
          color: ${props => props.theme.crcoTechBlue};
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .channels {
          font-size: 12px;
          color: #777e90;
        }
      }
    }
    .opt-in-link {
      margin-left: auto;
    }
    .avatar-wrapper {
      cursor: pointer;
    }
    .admin-bar {
      background: ${props => props.theme.crcoPaleBlue};
      border: 1px dotted ${props => props.theme.crcoTechBlue};
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 10px;
    }
    .ids {
      color: ${props => props.theme.crcoGrey};
      display: flex;
      gap: 10px;
      font-size: 0.8rem;
      margin-left: auto;
      .id {
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
    .mark-read-status {
      margin-left: auto;
      padding-left: 10px;
    }
  }
  .chat-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .chat-actions {
    display: flex;
  }
  .uploads {
    margin: 0 10px;
    text-align: right;
    .ant-upload-list-item {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      margin: 5px 0;
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: transparent;
    }
  }
  .sms {
    cursor: default;
    color: rgba(0, 0, 0, 0.5);
  }
  .ant-form {
    position: relative;
    margin: 10px;
    display: flex;
    align-items: center;
    * {
      padding: 0;
      border: 0;
    }
    div {
      flex: 1;
    }
    .ant-input-affix-wrapper {
      background: none;
    }
    textarea {
      height: 150px;
      border: 1px solid #a5d2f3;
      border-radius: 5px;
      padding: 10px 40px 10px 10px;
      resize: none;
    }
    button {
      background: none;
      color: ${props => props.theme.crcoTechBlue};
      opacity: 0.5;
      position: absolute;
      bottom: 5px;
      right: 10px;
      font-size: 1.2rem;
      z-index: 10;
      transition: 0.2s ease-in-out;
      &:disabled {
        pointer-events: none;
        opacity: 0;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .loading {
    display: grid;
    place-content: center;
    height: 100%;
  }
`

const AdminTemplatesCollapse = styled(Collapse)`
  width: 100%;
  padding: 15px 0;

  .ant-collapse-content {
    .chat-templates {
      padding-right: 10px;
      max-height: 500px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      ${props => props.theme.scrollbar}

      .template-label {
        overflow-wrap: break-word;
      }

      .template-name {
        color: ${props => props.theme.crcoGrey};
        font-size: 14px;
        margin-bottom: 10px;
        overflow-wrap: break-word;
        // max 3 lines with overflow ellipsis
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .template {
      border: 1px solid #e6e6e6;
      padding: 10px;
      border-radius: 5px;
      word-break: break-word;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        color: ${props => props.theme.crcoTechBlue};
        border-color: ${props => props.theme.crcoTechBlue};
      }
    }
    .no-templates {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
    }
  }
  .header {
    font-size: 16px;
  }
`

const ChatTemplatesModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px !important;
  }
`
const Messages = styled.div`
  background: ${props => props.theme.crcoPaleBlue};
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  overflow: hidden;
  padding: 8px;
  flex: 1;

  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    margin-top: auto;
    ${props => props.theme.scrollbar}
    .info-text {
      color: rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.sender === 'current-user' ? 'flex-end' : '')};
  margin: 20px 10px 0 10px;
  .message-info {
    display: flex;
    color: rgba(0, 0, 0, 0.4);
    &.current-user {
      justify-content: flex-end;
      text-align: right;
    }
    .sender-name {
      color: rgba(0, 0, 0, 0.5);
      font-weight: bold;
    }
  }
  .message-content {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    text-align: ${props => (props.sender === 'current-user' ? 'right' : '')};
    margin-top: 5px;
    .file-download {
      display: grid;
      margin: 5px 0;
    }
  }
  .device {
    margin-right: 5px;
  }
`

const Text = styled.p`
  text-align: left;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  white-space: pre-line;
  max-width: 100%;
  overflow-wrap: anywhere;

  &.notification {
    background: ${props => props.theme.crcoTechBlue};
    font-style: italic;
    font-weight: lighter;
    color: #fff;
  }

  img {
    max-width: 100%;
    max-height: 350px;
  }
`

const Status = styled.span`
  color: #999;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  margin: auto 5px;
  .read {
    .anticon {
      color: ${props => props.theme.crcoLettuce};
    }
  }
`

const InfoText = styled.p`
  opacity: ${props => (props.validInput ? '1' : '0')};
  color: #666;
  font-size: 0.7rem;
  margin: 4px 15px 4px 15px;
  text-align: right;
  transition: 0.2s ease-in-out;
`

const ChatTemplatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .chat-templates {
    padding-right: 10px;
    max-height: 500px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${props => props.theme.scrollbar}

    .template-label {
      overflow-wrap: break-word;
    }

    .template-name {
      color: ${props => props.theme.crcoGrey};
      font-size: 14px;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      // max 3 lines with overflow ellipsis
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .template {
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 5px;
    word-break: break-word;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${props => props.theme.crcoTechBlue};
      border-color: ${props => props.theme.crcoTechBlue};
    }
  }
  .no-templates {
    text-align: center;
    margin-bottom: 10px;
  }
`
