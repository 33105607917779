import { message, Row, Spin } from 'antd'
import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components/macro'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

export const EmailTemplatePreview = ({ template }) => {
  const [loading, setLoading] = useState(false)
  const [previewContent, setPreviewContent] = useState('')
  const { brandId } = useContext(BrandContext)

  // Request new preview when template changes
  useEffect(() => {
    if (template?.length <= 0) return
    setLoading(true)
    axios
      .post(`${NODE_URL}/brand/${brandId}/emailTemplate/NEW/preview`, {
        template,
      })
      .then(res => {
        setPreviewContent(res.data.preview)
      })
      .catch(err => {
        message.error(
          err?.response?.data?.error ||
            'The email template preview could not be generated. Please try again later.'
        )
        setPreviewContent('')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [template, brandId])

  return (
    <>
      {loading && (
        <StyledRow type='flex' align='middle' justify='center'>
          <Spin />
        </StyledRow>
      )}
      {!loading && <div dangerouslySetInnerHTML={{ __html: previewContent }} />}
    </>
  )
}

const StyledRow = styled(Row)`
  padding-top: 20px;
  padding-bottom: 20px;
`
