import {
  EyeOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  MailOutlined,
  BarChartOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { message, Button, Tag, Tooltip, Divider, Drawer, notification, Modal } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, Select } from 'formik-antd'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useRef, useState, useEffect } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import CampaignInvite from './CampaignInvite'
import { SavingIndicator } from '../../../components/general/SavingIndicator'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
import { socialProfileToUrl } from '../../../utils'
import { SearchProfile } from '../search/SearchProfile'
const socialIcons = { instagram, youtube, tiktok }

export const UpdateReport = ({ isLoading, onUpdate, daysOld }) => {
  if (daysOld <= 0) return <></>
  return (
    <UpdateReportWrapper>
      <Divider />
      <p>This report is {daysOld} days old. You may purchase an updated report for 1 credit.</p>
      <Button type='link' loading={isLoading} onClick={onUpdate}>
        Update Report
      </Button>
    </UpdateReportWrapper>
  )
}

export const CreatorListItem = ({ creator, campaigns, selectedCampaignId, creatorListId }) => {
  const { socialProfile } = creator

  const {
    brandId,
    updateCreatorListItem,
    archiveCreatorListItem,
    fetchBrandData,
    purchaseAudienceData,
  } = useContext(BrandContext)

  const submitRef = useRef(0)

  const [savingStatus, setSavingStatus] = useState(undefined)
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [deepInsightsLoading, setDeepInsightsLoading] = useState(false)
  const [inviteCampaignId, setInviteCampaignId] = useState(undefined)
  const [profileOpen, setProfileOpen] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { data: invitedContacts } = useQuery(['search-invites', brandId], async (key, brandId) => {
    const { data } = await axios.get(`${NODE_URL}/campaign-invites/${brandId}/?page=1`)
    return data
  })

  // STATUS TAGS
  const tags = [
    { value: 'Awaiting response', color: 'gold' },
    { value: 'Interested', color: 'cyan' },
    { value: 'Not interested', color: 'volcano' },
    { value: 'In discussion', color: 'purple' },
    { value: 'In negotiation', color: 'geekblue' },
    { value: 'To be approved', color: 'orange' },
    { value: 'Invited', color: 'blue' },
    { value: 'Approved', color: 'green' },
    { value: 'Rejected', color: 'red' },
  ]

  const tagRender = props => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = event => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={tags.find(tag => tag.value === value) && tags.find(tag => tag.value === value).color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '2px',
        }}>
        {label}
      </Tag>
    )
  }

  const initialValues = {
    tags: creator.tags || [],
    feedback: creator.feedback,
  }

  // #region FUNCTIONS
  const handleUpdate = async data => {
    setSavingStatus('submitting')
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      if (thisSubmit === submitRef.current) {
        const result = await updateCreatorListItem(creator.id, data)
        queryCache.invalidateQueries('creator-list-items', creatorListId)
        if (result.error) {
          setSavingStatus('error')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        } else {
          setSavingStatus('success')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        }
      }
    }, 300)
  }

  const handleArchive = async creatorListId => {
    setArchiveLoading(true)
    const result = await archiveCreatorListItem(creator.id)
    queryCache.invalidateQueries('creator-list-items', creatorListId)
    queryCache.invalidateQueries('archived-creators', brandId)
    if (result.status === 200) message.success('Creator has been archived.')
    else message.error('Something went wrong.')
    setArchiveLoading(false)
  }

  const handleInsights = async (event, force = false) => {
    setDeepInsightsLoading(true)

    // check for existing report
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/audience-data`, {
      params: { socialProfileId: socialProfile.id },
    })
    if (data?.reportUrl && !force) {
      const daysOld = parseInt(
        moment().diff(moment(data?.report?.report_info?.created), 'days', true)
      )

      notification.info({
        message: 'Deep Insights Found',
        description: (
          <AlertWrapper>
            <p>
              Report found for <b>{socialProfile.username}</b>.
            </p>
            <div className='buttons'>
              <Button type='primary'>
                <a href={data.reportUrl} download>
                  Download
                </a>
              </Button>
              <Button
                type='secondary'
                onClick={() => {
                  window.location.href = '/creator-search/deep-insights'
                }}>
                View All
              </Button>
            </div>
            <UpdateReport
              // FIXME: not getting loading state
              isLoading={deepInsightsLoading}
              onUpdate={handleInsights.bind(this, null, true)}
              daysOld={daysOld}
            />
          </AlertWrapper>
        ),
        duration: 0,
      })
    } else {
      // purchase & download new report
      await purchaseAudienceData(brandId, socialProfile.id, force)
    }

    setDeepInsightsLoading(false)
  }

  // CAMPAIGN INVITE
  useEffect(() => {
    if (selectedCampaignId) {
      setInviteCampaignId(selectedCampaignId)
    }
  }, [selectedCampaignId, campaigns, inviteCampaignId])
  // #endregion FUNCTIONS

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleUpdate}>
        {({ setFieldValue, submitForm }) => (
          <Form>
            <div className='creator-info'>
              <div className='profile'>
                <div
                  className='avatar'
                  style={{
                    backgroundImage: `url(${socialProfile.profilePicUrl})`,
                  }}
                />
                <div className='info'>
                  <div className='fullname'>{socialProfile.fullname}</div>
                  <a
                    className='username'
                    href={socialProfileToUrl(socialProfile)}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      className='platform'
                      src={socialIcons[socialProfile.platform]}
                      alt={socialProfile.platform}
                    />
                    @{socialProfile.username}
                    {socialProfile.metaData.is_verified && (
                      <span className='verified'>
                        <CheckCircleOutlined />
                      </span>
                    )}
                  </a>
                </div>
                <div className='stats'>
                  <span className='stat'>
                    {numeral(socialProfile.followerCount).format('0.0a')}
                    <p className='label'>
                      {socialProfile.platform === 'youtube' ? 'subscribers' : 'followers'}
                    </p>
                  </span>
                  {socialProfile.platform !== 'youtube' && (
                    <span className='stat'>
                      {(Math.round(socialProfile.engagement * 1000) / 1000).toFixed(2)}%
                      <p className='label'>engagement</p>
                    </span>
                  )}
                  {socialProfile.platform !== 'instagram' && (
                    <span className='stat'>
                      {numeral(socialProfile.avgViews).format('0a')}
                      <p className='label'>avg. views</p>
                    </span>
                  )}
                </div>
              </div>

              <div className='profile-notes'>
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  virtual={false}
                  name='tags'
                  showArrow
                  mode='tags'
                  tagRender={tagRender}
                  options={tags}
                  placeholder='Status'
                  onChange={e => {
                    setFieldValue('tags', e)
                    submitForm()
                  }}
                />
                <Input.TextArea
                  name='feedback'
                  placeholder='Notes / Comments'
                  onChange={e => {
                    setFieldValue('feedback', e.target.value)
                    submitForm()
                  }}
                />
              </div>
            </div>
            <div className='profile-actions'>
              {/* OPEN PROFILE DRAWER */}
              <Tooltip title='View Details'>
                <Button
                  type='link'
                  onClick={() => {
                    setProfileOpen(true)
                  }}>
                  <EyeOutlined />
                </Button>
              </Tooltip>

              {/* INVITE TO CAMPAIGN - indicator if contact info isn't available */}
              {socialProfile?.metaData?.contactsError ? (
                <div className='error'>
                  <Tooltip
                    title={
                      socialProfile?.metaData?.contactsError?.message ||
                      `Couldn't fetch contact info`
                    }>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </div>
              ) : !socialProfile?.metaData?.contactsFetched ? (
                <div className='warning'>
                  <Tooltip title={`Contact info hasn't fetched.`}>
                    <HourglassOutlined />
                  </Tooltip>
                </div>
              ) : (
                <>
                  <Tooltip
                    title={
                      brandData &&
                      invitedContacts?.invites?.find(
                        contact => contact.metaData?.profile?.username === socialProfile.username
                      )
                        ? 'Invited'
                        : 'Invite to Campaign'
                    }>
                    <Button
                      type='link'
                      disabled={
                        brandData &&
                        invitedContacts?.invites?.find(
                          contact => contact.metaData?.profile?.username === socialProfile.username
                        )
                      }
                      icon={<MailOutlined />}
                      onClick={() => setInviteModalVisible(true)}
                    />
                  </Tooltip>
                  <Modal
                    title='Invite to campaign'
                    open={inviteModalVisible}
                    onCancel={() => {
                      setInviteModalVisible(false)
                    }}
                    destroyOnClose
                    footer={null}>
                    <CampaignInvite
                      socialProfileId={socialProfile.id}
                      username={socialProfile.username}
                      close={() => setInviteModalVisible(false)}
                    />
                  </Modal>
                </>
              )}

              {/* PURCHASE/FETCH DEEP INSIGHTS */}
              <Tooltip title='Get Deep Insights'>
                <Button
                  type='link'
                  icon={<BarChartOutlined />}
                  loading={deepInsightsLoading}
                  onClick={handleInsights}
                />
              </Tooltip>

              {/* ARCHIVE CREATOR (move from list to archive) - indicator when saving */}
              {savingStatus ? (
                <SavingIndicator savingStatus={savingStatus} />
              ) : (
                <Tooltip title='Archive Creator'>
                  <Button
                    type='link'
                    danger
                    icon={<DeleteOutlined />}
                    loading={archiveLoading}
                    onClick={handleArchive}
                  />
                </Tooltip>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {/* CREATOR PROFILE */}
      <Drawer
        title={`${
          socialProfile.platform === 'instagram'
            ? 'Instagram'
            : socialProfile.platform === 'tiktok'
              ? 'TikTok'
              : socialProfile.platform === 'youtube' && 'YouTube'
        } Profile Details`}
        width={window.innerWidth > 700 ? 600 : window.innerWidth}
        open={profileOpen}
        onClose={() => {
          setProfileOpen(false)
        }}
        destroyOnClose>
        <SearchProfile socialProfile={socialProfile} close={() => setProfileOpen(false)} />
      </Drawer>
    </Wrapper>
  )
}

const UpdateReportWrapper = styled.div`
  width: 100%;
  p {
    color: ${props => props.theme.crcoGrey};
    font-size: 0.8rem;
    margin: 0;
  }
  button.ant-btn.ant-btn-link {
    padding: 0;
  }
`

export const AlertWrapper = styled.div`
  .buttons {
    display: flex;
    gap: 12px;
  }
`

const Wrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  form {
    display: flex;
    gap: 10px;
  }
  .creator-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .profile {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 5px 10px;
    .avatar {
      background-color: #eee;
      height: 50px;
      min-width: 50px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
    }
    .info {
      word-break: break-word;
      margin-right: auto;
    }
    .username {
      color: ${props => props.theme.crcoTechBlue};
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 8px;
      .platform {
        height: 18px;
        width: 18px;
      }
      .verified {
        background: ${props => props.theme.crcoTechBlue};
        color: #fff;
        border-radius: 50%;
        padding: 1px;
        display: grid;
        place-content: center;
        font-size: 0.8rem;
      }
    }
    .fullname {
      font-family: 'Campton-Light', sans-serif;
      color: ${props => props.theme.crcoBlack};
    }
  }
  .stats {
    display: flex;
    gap: 20px;
  }
  .profile-notes {
    display: flex;
    gap: 10px;
    .ant-select {
      flex: 1;
      height: 100%;
    }
    .ant-select-selector {
      height: 100%;
    }
    .ant-input {
      flex: 2;
    }
  }
  .error,
  .warning {
    display: grid;
    place-content: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
  .warning {
    background: #ffa50033;
    color: orange;
  }
  .error {
    background: #ff000033;
    color: red;
  }
  .profile-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    button {
      padding: 0;
      margin: 0;
      border: 0;
      height: 20px;
      width: 20px;
    }
  }

  // desktop
  @media only screen and (min-width: 1400px) {
    .creator-info {
      flex-direction: row;
    }
    .profile-notes {
      flex: 1;
      flex-direction: column;
    }
  }
`
