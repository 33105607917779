import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Checkbox, Tooltip, Button } from 'antd'
import React, { useState, useContext } from 'react'
import { useQuery } from 'react-query'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import { BOOK_DEMO_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import check from '../../images/pricing/checkmark-blue.svg'
import cross from '../../images/pricing/grey-cross.svg'

export const BrandPricing = ({
  yearly,
  openPayment,
  openAffiliatePayment,
  openAddonPayment,
  loading,
  affiliateLoading,
  addonLoading,
}) => {
  const brandContext = useContext(BrandContext)
  const { data: brandData } = useQuery(
    ['brand', brandContext?.brandId],
    brandContext?.fetchBrandData
  )

  const [selectedPlan, setSelectedPlan] = useState(false)
  const [agreementOpen, setAgreementOpen] = useState(false)
  const [agreementAccepted, setAgreementAccepted] = useState(false)

  const basePrices = {
    'self-serve': 495,
    'start-up': 1995,
    trailblazer: 2995,
    bespoke: 3995,
  }
  const planPrices = {
    'self-serve': yearly ? Math.floor(basePrices['self-serve'] * 0.85) : basePrices['self-serve'],
    'start-up': yearly ? Math.floor(basePrices['start-up'] * 0.85) : basePrices['start-up'],
    trailblazer: yearly ? Math.floor(basePrices.trailblazer * 0.85) : basePrices.trailblazer,
    bespoke: yearly ? Math.floor(basePrices.bespoke * 0.85) : basePrices.bespoke,
  }

  const features = {
    prices: [
      { title: 'One-time onboarding', tooltip: 'Service' },
      { title: `Base ${yearly ? 'annual' : 'monthly'} subscription`, tooltip: 'Software' },
      { title: 'Account manager', tooltip: 'Service' },
      { title: 'Total monthly fee', tooltip: null },
    ],
    services: [
      {
        title: 'Strategy',
        tooltip: 'Identify tangible goals and help you build strategy and campaign briefs.',
      },
      {
        title: 'Creator recruitment',
        tooltip: 'Build multi-touch workflows to recruit specific creators.',
      },
      { title: 'Creator management', tooltip: 'Handle communications and negotiations.' },
      { title: 'Custom reporting', tooltip: 'Custom reporting needs.' },
      {
        title: 'Campaign health checks',
        tooltip: 'Work with a campaign specialist to keep things on track.',
      },
      {
        title: 'Dedicated Slack channel',
        tooltip: null,
      },
      {
        title: 'Social media content management',
        tooltip: null,
      },
      {
        title: 'Social media ads management',
        tooltip: null,
      },
    ],
    campaigns: [
      {
        title: 'Content only',
        tooltip: 'Drive UGC at lower prices - no social posting required.',
      },
      {
        title: 'Gifted & paid',
        tooltip: 'Standard influencer campaigns.',
      },
      {
        title: 'Giveaways',
        tooltip: 'Grow your followers and engagement when creators host giveaways.',
      },
      {
        title: 'Experiential',
        tooltip: 'Retail experiences, product demos, custom videos, contests, and more.',
      },
      {
        title: 'Affiliate (add-on)',
        tooltip: 'Integrated with Awin, and Share-A-Sale.',
      },
      {
        title: 'Community exposure',
        tooltip: null,
      },
    ],
    creators: [
      {
        title: 'Creator search & database',
        tooltip: '300 million creators on Instagram, TikTok, and YouTube at your fingertips.',
      },
      {
        title: 'Creator contact exports',
      },
      {
        title: 'Advanced creator insights',
        tooltip: 'Industry leading data on all social profiles.',
      },
      {
        title: 'Newsletter feature',
        tooltip:
          'Automatically connect with 175,000+ influencers who are already using Creator.co.',
      },
    ],
  }

  // monthly prices
  const selfServePrice = useSpring({
    val: planPrices['self-serve'],
    from: { val: basePrices['self-serve'] },
    config: {
      duration: 300,
    },
  })

  const startUpPrice = useSpring({
    val: planPrices['start-up'],
    from: { val: basePrices['start-up'] },
    config: {
      duration: 300,
    },
  })

  const trailblazerPrice = useSpring({
    val: planPrices.trailblazer,
    from: { val: basePrices.trailblazer },
    config: {
      duration: 300,
    },
  })

  const bespokePrice = useSpring({
    val: planPrices.bespoke,
    from: { val: basePrices.bespoke },
    config: {
      duration: 300,
    },
  })

  const plans = [
    {
      name: 'Self-Serve',
      accountManager: null,
      totalMonthly: `$${planPrices['self-serve']}`,
      strategy: false,
      creatorRecruitment: false,
      creatorManagement: false,
      customReporting: false,
      healthChecks: false,
      slack: false,
      contentOnly: true,
      giftedAndPaid: true,
      giveaways: true,
      experiential: false,
      affiliates: 95,
      search: true,
      contacts: '250 / month',
      insights: '100 / month',
      newsletter: false,
    },
    {
      name: 'Start-Up',
      accountManager: `$${planPrices['start-up'] - planPrices['self-serve']} / month`,
      totalMonthly: `$${planPrices['start-up']}`,
      strategy: true,
      creatorRecruitment: 'Up to 200',
      creatorManagement: false,
      customReporting: false,
      healthChecks: 'Monthly',
      slack: false,
      contentOnly: true,
      giftedAndPaid: true,
      giveaways: true,
      experiential: false,
      affiliates: 95,
      search: true,
      contacts: '250 / month',
      insights: '150 / month',
      newsletter: true,
    },
    {
      name: 'Trailblazer',
      accountManager: `$${planPrices.trailblazer - planPrices['self-serve']} / month`,
      totalMonthly: `$${planPrices.trailblazer}`,
      strategy: true,
      creatorRecruitment: 'Up to 400',
      creatorManagement: true,
      customReporting: false,
      healthChecks: 'Bi-weekly',
      slack: true,
      contentOnly: true,
      giftedAndPaid: true,
      giveaways: true,
      experiential: false,
      affiliates: 95,
      search: true,
      contacts: '250 / month',
      insights: '200 / month',
      newsletter: true,
    },
    {
      name: 'Bespoke',
      accountManager: `$${planPrices.bespoke - planPrices['self-serve']} / month`,
      totalMonthly: `$${planPrices.bespoke}+`,
      strategy: true,
      creatorRecruitment: 'Customized',
      creatorManagement: true,
      customReporting: true,
      healthChecks: 'Weekly',
      slack: true,
      contentOnly: true,
      giftedAndPaid: true,
      giveaways: true,
      experiential: true,
      affiliates: 95,
      search: true,
      contacts: 'Customized',
      insights: 'Customized',
      newsletter: true,
    },
  ]

  return (
    <Wrapper>
      <div className='price-cards brand'>
        <div
          className={`price-card ${
            brandData?.activeSubscriptions.includes('self-serve') ? 'active' : ''
          }`}>
          <div className='flag active'>
            <span>Active</span>
          </div>
          <div className='intro'>
            <p className='plan-name'>Self-Serve</p>
            <p className='stylized'>DIY</p>
            <div className='price'>
              <span className='value'>
                <animated.span>
                  {selfServePrice.val.interpolate(val => `$${Math.floor(val)}`)}
                </animated.span>
              </span>{' '}
              per month
            </div>
            <p>
              Build campaign briefs, invite creators, and automatically capture content and data.
              One dashboard to manage all your influencer campaigns.
            </p>
          </div>
          <div className='details'>
            <p>Our base brand plan includes:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> Creator discovery (200+ million
                profiles)
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Content only, gifted, and paid
                campaigns
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Giveaways and contests
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Affiliate Campaigns ($95 add on)
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Advanced creator insights
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Social Listening
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Email Support
              </li>
            </ul>
            <Button
              type='primary'
              onClick={() => {
                setSelectedPlan('self-serve-3')
                setAgreementOpen(true)
              }}
              loading={loading === 'self-serve-3'}>
              Get Started
            </Button>
          </div>
        </div>
        <div
          className={`price-card ${
            brandData?.activeSubscriptions.includes('start-up') ? 'active' : ''
          }`}>
          <div className='flag active'>
            <span>Active</span>
          </div>
          <div className='intro'>
            <p className='plan-name'>Start-Up</p>
            <p className='stylized'>Get expert assistance</p>
            <div className='price'>
              <span className='value'>
                <animated.span>
                  {startUpPrice.val.interpolate(val => `$${Math.floor(val)}`)}
                </animated.span>
              </span>{' '}
              per month
            </div>
            <p>
              We provide assistance with strategy, brief building, and basic recruiting to drive
              successful campaigns. For only ${planPrices['start-up'] - planPrices['self-serve']}{' '}
              more per month.
            </p>
          </div>
          <div className='details'>
            <p>Everything in Self-Serve, plus:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> Account Manager
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Campaign Strategy
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Monthly health checks
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Newsletter Feature
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Basic Recruitment
              </li>
            </ul>
            <Button
              type='primary'
              onClick={() => {
                setSelectedPlan('start-up-4')
                setAgreementOpen(true)
              }}
              loading={loading === 'start-up-4'}>
              Get Started
            </Button>
          </div>
        </div>
        <div
          className={`price-card ${
            brandData?.activeSubscriptions.includes('trailblazer') ? 'active' : 'popular'
          }`}>
          <div className='flag active'>
            <span>Active</span>
          </div>
          <div className='flag popular'>
            <span>Popular</span>
          </div>
          <div className='intro'>
            <p className='plan-name'>Trailblazer</p>
            <p className='stylized'>Our most popular plan</p>
            <div className='price'>
              <span className='value'>
                <animated.span>
                  {trailblazerPrice.val.interpolate(val => `$${Math.floor(val)}`)}
                </animated.span>
              </span>{' '}
              per month
            </div>
            <p>
              Save over 40 hours per month with help from a dedicated Account Manager who will
              handle the bulk of your influencer campaigns, from strategy to reporting.
            </p>
          </div>
          <div className='details'>
            <p>Everything in Start-Up, plus:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> Creator management
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> 2x recruitment
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Bi-weekly health checks
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Dedicated Slack channel
              </li>
            </ul>
            <Button
              type='primary'
              onClick={() => {
                setSelectedPlan('trailblazer-4')
                setAgreementOpen(true)
              }}
              loading={loading === 'trailblazer-4'}>
              Get Started
            </Button>
          </div>
        </div>
        <div
          className={`price-card bespoke ${
            brandData?.activeSubscriptions.includes('bespoke') ? 'active' : ''
          }`}>
          <div className='flag active'>
            <span>Active</span>
          </div>
          <div className='intro'>
            <p className='plan-name'>Bespoke</p>
            <p className='stylized'>Customized to your needs</p>
            <div className='price'>
              <span className='value'>
                <animated.span>
                  {bespokePrice.val.interpolate(val => `$${Math.floor(val)}+`)}
                </animated.span>
              </span>{' '}
              per month
            </div>
            <p>
              Need to work with over 50 influencers per month or drive more conversion? Bespoke
              subscriptions are tailored for extraordinary campaigns.
            </p>
          </div>
          <div className='details'>
            <p>Everything in Trailblazer, plus:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> A-list talent
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Experiential campaign management
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Hyper-targeted recruitment
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Weekly strategy calls
              </li>
            </ul>
            <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
              <Button type='primary'>Book Demo</Button>
            </a>
          </div>
        </div>
      </div>

      <div className='price-card add-ons'>
        <div className='intro'>
          <p className='plan-name'>Add Ons</p>
        </div>
        <div className='add-on'>
          <p className='stylized'>Affiliate Integration</p>
          <div className='price'>
            <span className='value'>$95</span>
            <br />
            per month + applicable commissions
          </div>
          <p>Expose your affiliate program to 175,000+ micro-influencers and counting.</p>
          {!brandData?.affiliateSubscription && (
            <div className='cta'>
              <Button
                className='add-on-btn'
                type='primary'
                onClick={() => {
                  openAffiliatePayment()
                }}
                loading={affiliateLoading}>
                Get Started
              </Button>
            </div>
          )}
        </div>
        <div className='add-on'>
          <p className='stylized'>Social Media Ads Management</p>
          <div className='price'>
            <span className='value'>$1495</span>
            <br />
            per month + % of budget (or commissions)
          </div>
          <p>
            Our Meta Certified team has the experience and acumen to build successful social ads
            strategy, this includes boosting and whitelisting influencer content.
          </p>
          <div className='cta'>
            {brandData ? (
              !brandData?.addons?.includes('social-media-ads-management-plan') && (
                <div className='cta'>
                  <Button
                    className='add-on-btn'
                    type='primary'
                    onClick={() => {
                      openAddonPayment('social-media-ads-management-plan')
                    }}
                    loading={addonLoading === 'social-media-ads-management-plan'}>
                    Get Started
                  </Button>
                </div>
              )
            ) : (
              <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
                <Button type='primary'>Book Demo</Button>
              </a>
            )}
          </div>
        </div>
        <div className='add-on'>
          <p className='stylized'>Social Media Content Management</p>
          <div className='price'>
            <span className='value'>$1995+</span>
            <br />
            per month
          </div>
          <p>
            Our in house brand strategists work with you to build a winning content strategy across
            multiple social media platforms.
          </p>
          <div className='cta'>
            <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
              <Button type='primary'>Book Demo</Button>
            </a>
          </div>
        </div>
      </div>

      <div className='pricing-tables'>
        <div className='pricing-table fees'>
          <div className='column features'>
            <span className='cell title'>Fees</span>
            {features.prices.map((feature, index) => (
              <span key={index} className='cell feature'>
                {feature.title}
                {feature.tooltip && (
                  <Tooltip arrow title={feature.tooltip}>
                    <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                  </Tooltip>
                )}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell'>$395</span>
              <span className='cell'>${planPrices['self-serve']} / month</span>
              <span className='cell'>
                {plan.accountManager || <img className='icon' src={cross} alt='Not included' />}
              </span>
              <span className='cell fee'>{plan.totalMonthly} / month</span>
            </div>
          ))}
        </div>
        <div className='pricing-table'>
          <div className='column features'>
            <span className='cell title'>Services</span>
            {features.services.map((feature, index) => (
              <span key={index} className='cell feature'>
                {feature.title}
                {feature.tooltip && (
                  <Tooltip arrow title={feature.tooltip}>
                    <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                  </Tooltip>
                )}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell'>
                {plan.strategy ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>
                {plan.creatorRecruitment || <img className='icon' src={cross} alt='Not included' />}
              </span>
              <span className='cell'>
                {plan.creatorManagement ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>
                {plan.customReporting ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>
                {plan.healthChecks || <img className='icon' src={cross} alt='Not included' />}
              </span>
              <span className='cell'>
                {plan.slack ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>$1995+ / month</span>
              <span className='cell'>
                $1495+ / month
                <span className='subtext'>+ applicable performance fees</span>
              </span>
            </div>
          ))}
        </div>
        <div className='pricing-table'>
          <div className='column features'>
            <span className='cell title'>Campaign Strategies</span>
            {features.campaigns.map((feature, index) => (
              <span key={index} className='cell feature'>
                {feature.title}
                {feature.tooltip && (
                  <Tooltip arrow title={feature.tooltip}>
                    <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                  </Tooltip>
                )}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell'>
                {plan.contentOnly ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>
                {plan.giftedAndPaid ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>
                {plan.giveaways ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>
                {plan.experiential ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>+${plan.affiliates} / month</span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
            </div>
          ))}
        </div>
        <div className='pricing-table'>
          <div className='column features'>
            <span className='cell title'>Creators</span>
            {features.creators.map((feature, index) => (
              <span key={index} className='cell feature'>
                {feature.title}
                {feature.tooltip && (
                  <Tooltip arrow title={feature.tooltip}>
                    <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                  </Tooltip>
                )}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell'>
                {plan.search ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
              <span className='cell'>{plan.contacts}</span>
              <span className='cell'>{plan.insights}</span>
              <span className='cell'>
                {plan.newsletter ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title='Subscription Agreement'
        open={agreementOpen}
        footer={null}
        onClose={() => {
          setSelectedPlan(false)
          setAgreementOpen(false)
        }}
        onCancel={() => {
          setSelectedPlan(false)
          setAgreementOpen(false)
        }}>
        <p>
          Thank you for your interest in subscribing to our platform! Kindly take note of the
          following:
        </p>
        <ul>
          <li>
            <b>Term:</b> Minimum 3-month commitment, paid monthly. Subscription will automatically
            renew month-to-month.
          </li>
          <li>
            <b>Cancellation:</b> 30-day notice required for cancellation to wind down campaigns and
            cancel pending influencers.
          </li>
          <li>
            <b>Influencer Payments:</b> Payments through our platform are subject to a 5% processing
            fee for both the brand and creator (no additional PayPal or merchant fees).
          </li>
        </ul>
        <p>
          For more information, please review our full{' '}
          <a href='https://creator.co/brand-terms/' target='_blank' rel='noopener noreferrer'>
            Terms & Conditions
          </a>{' '}
          for brands and agencies.
        </p>
        <Checkbox
          onChange={e => {
            setAgreementAccepted(e.target.checked)
          }}>
          I agree to the terms and conditions as outlined above.
        </Checkbox>
        <div style={{ display: 'flex', gap: '12px', marginTop: '30px' }}>
          <Button type='secondary' onClick={() => setAgreementOpen(false)}>
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setAgreementOpen(false)
              openPayment(selectedPlan)
            }}
            disabled={!agreementAccepted}>
            Continue
          </Button>
        </div>
      </Modal>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  max-width: 100vw;
  overflow: auto;
  position: relative;
  z-index: 1;
`
