import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { Button, Tooltip, message, notification, Alert } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, InputNumber, Switch } from 'formik-antd'
import React, { useState, useContext, useEffect } from 'react'
import { useQuery, queryCache } from 'react-query'
import * as Yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

const BrandImapInfo = () => {
  const [editMode, setEditMode] = useState(false)
  const [testEmailLoading, setTestEmailLoading] = useState(false)

  const { fetchCurrentUserData } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { data: userData } = useQuery('user', fetchCurrentUserData)

  const imapState = brandData?.extraData?.imapState

  const formSchema = Yup.object().shape({
    host: Yup.string().required('Required'),
    user: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    portNumber: Yup.number().required('Required').positive('Must be a positive number'),
    isSecure: Yup.boolean().required('Required'),
  })

  const initialValues = {
    host: brandData?.extraData?.imapCredentials?.host,
    user: brandData?.extraData?.imapCredentials?.user,
    password: brandData?.extraData?.imapCredentials?.password,
    portNumber: brandData?.extraData?.imapCredentials?.portNumber,
    isSecure: !!brandData?.extraData?.imapCredentials?.isSecure,
  }

  const handleTestEmailButton = values => {
    axios
      .post(`${NODE_URL}/brand/${brandId}/email/imap/test`, { ...values })
      .then(() => {
        notification.success({
          message: 'Successful connection',
          description: `IMAP settings are working fine.`,
        })
      })
      .catch(() => {
        notification.error({
          message: 'Invalid IMAP Credentials',
          description:
            'An error occured when testing the access to your inbox. Please double check your credentials and try again.',
          duration: 0,
        })
      })
      .finally(() => {
        setEditMode(false)
        setTestEmailLoading(false)
      })
  }

  const handleSubmit = data => {
    axios
      .post(`${NODE_URL}/brand/${brandId}/email/imap/save`, data)
      .then(() => {
        message.success('IMAP Settings successfully updated!')
        queryCache.invalidateQueries('brand')
        setEditMode(false)
      })
      .catch(err => {
        message.error(`Failed to save IMAP settings! ${err.err || ''}`)
      })
      .finally(() => {
        setEditMode(false)
      })
  }

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.replace(/#/g, '')
      const element = document.getElementById(hash)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={data => handleSubmit(data)}>
      {({ isSubmitting, submitForm, values, errors }) => {
        return (
          <Form className='account-section' id='imap-settings'>
            <div className='section-header'>
              <h2 className='section-title'>
                IMAP Settings{' '}
                <Tooltip
                  className='tooltip-icon'
                  arrow
                  title={`An IMAP connection lets you receive emails from our platform using your own email system. This is a required configuration to gain access to our automated email sequences.`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </h2>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {!editMode ? (
                  <>
                    <Button
                      className='test-button'
                      loading={testEmailLoading}
                      onClick={() => {
                        handleTestEmailButton(values, userData.email)
                        setTestEmailLoading(true)
                      }}
                      disabled={Object.keys(errors).length !== 0 || !values.host}
                      type='link'>
                      <MailOutlined />
                      Send Test Email
                    </Button>
                    <Button type='link' onClick={() => setEditMode(true)}>
                      <EditOutlined /> Edit
                    </Button>
                  </>
                ) : (
                  <div className='buttons'>
                    <Button danger type='link' onClick={() => setEditMode(false)}>
                      <CloseCircleOutlined /> Cancel
                    </Button>
                    <Button
                      type='link'
                      onClick={submitForm}
                      loading={isSubmitting}
                      disabled={Object.keys(errors).length !== 0}>
                      <SaveOutlined /> Save
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className='section-body'>
              <div className='form-row-wrapper'>
                <FormItem
                  name='host'
                  label='IMAP Server'
                  info='Your email provider IMAP hostname'
                  width={'100%'}>
                  <Input name='host' placeholder='e.g. imap.relay.com' disabled={!editMode} />
                </FormItem>

                <FormItem
                  name='portNumber'
                  label='Port Number'
                  info='The port of your IMAP server.'>
                  <InputNumber
                    name='portNumber'
                    placeholder='e.g. 993'
                    controls={false}
                    disabled={!editMode}
                    style={{ maxWidth: '100%' }}
                  />
                </FormItem>
              </div>

              <div className='form-row-wrapper'>
                <FormItem
                  name='user'
                  label='User'
                  info='The username/email address you use to sign in to your email account.'
                  width={'100%'}>
                  <Input name='user' placeholder='e.g. creatorco@gmail.com' disabled={!editMode} />
                </FormItem>

                <FormItem
                  name='password'
                  label='Password'
                  info='If your email server is a major provider (e.g., Gmail or Outlook) please generate an App Password from your email settings, otherwise please enter your password.'
                  width={'100%'}>
                  <Input.Password
                    name='password'
                    placeholder='*********'
                    disabled={!editMode}
                    autoComplete='new-password'
                  />
                </FormItem>
              </div>

              <div className='form-row-wrapper'>
                <FormItem
                  name='isSecure'
                  label='Enable SSL'
                  info='Indicates if your server uses SSL to accept connections.'
                  width={'100%'}>
                  <Switch name='isSecure' disabled={!editMode} />
                </FormItem>
              </div>
              {imapState?.healthy === false && (
                <div className='form-row-wrapper'>
                  <Alert
                    style={{ width: '100%' }}
                    message={imapState?.error || 'Unknown IMAP connection error!'}
                    type='error'
                  />
                </div>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
export default BrandImapInfo
