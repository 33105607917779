import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Select, message, notification } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { CreateTemplateModal } from '../../components/forms/ChatTemplate'
import CreatorAvatar from '../../components/general/CreatorAvatar'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'
import { getProfilePicUrl } from '../../utils'

export const BulkMessageModal = ({
  campaignId,
  numSelected,
  allSelected,
  excludedOptInIds,
  optIns,
  bulkMessageModalOpen,
  setBulkMessageModalOpen,
  optInFilters,
}) => {
  const { role, fetchCurrentUserData } = useContext(UserContext)
  const { brandId, fetchMessageTemplates } = useContext(BrandContext)
  const [templateOptions, setTemplateOptions] = useState([])
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  // determine the selected optIns in case of manual selections (when allSelected is false)
  const selectedOptIns = optIns?.filter(optIn => !excludedOptInIds.includes(optIn.id))

  // get first 5 selected creators for avatar display
  const avatarLimit = 5
  const splicedOptIns = selectedOptIns?.slice(0, avatarLimit)

  const fetchAdminMessageTemplates = async () => {
    if (role !== 'administrator') return [] // only admins can use admin templates
    const { data } = await axios.get(`${NODE_URL}/admin/message-templates`)
    return data?.messageTemplates
  }

  const { data: adminTemplates, status: adminTemplatesStatus } = useQuery(
    ['admin-message-templates'],
    fetchAdminMessageTemplates
  )

  const { data: brandTemplates, status: brandTemplatesStatus } = useQuery(
    ['brand-message-templates', brandId],
    fetchMessageTemplates
  )

  const { data: userData } = useQuery('current-user', fetchCurrentUserData)

  useEffect(() => {
    if (adminTemplates && brandTemplates) {
      setTemplateOptions([...adminTemplates, ...brandTemplates])
    }
  }, [adminTemplates, brandTemplates])

  const handleBulkMessage = async () => {
    if (!userData || !selectedTemplateId || numSelected <= 1) return
    setSubmitting(true)

    try {
      await axios.post(`${NODE_URL}/brand/${brandId}/bulk-message`, {
        userId: userData.id,
        campaignId,
        allSelected,
        excludedOptInIds,
        selectedOptInIds: selectedOptIns.map(optIn => optIn.id),
        messageTemplateId: selectedTemplateId,
        optInFilters, // Used for filtering optIns in the backend when all selected
      })

      notification.success({
        message: 'Bulk message sent',
        description: 'This may take a few minutes to complete.',
      })
      setBulkMessageModalOpen(false)
    } catch (err) {
      message.error(err.response?.data?.err || 'Error sending bulk message.')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal
      title={`Bulk message (${numSelected} creators)`}
      open={bulkMessageModalOpen}
      okText='Send'
      onOk={handleBulkMessage}
      okButtonProps={{
        disabled: !userData || !selectedTemplateId || numSelected <= 1,
        loading: submitting,
      }}
      onCancel={() => setBulkMessageModalOpen(false)}
      destroyOnClose>
      <Wrapper>
        <div className='avatars'>
          {splicedOptIns?.map((optIn, i) => (
            <Avatar key={i} optIn={optIn} />
          ))}
          {numSelected > avatarLimit && (
            <span className='label'>+{numSelected - avatarLimit} more</span>
          )}
        </div>

        <Select
          name='template'
          placeholder='Select template'
          loading={[adminTemplatesStatus, brandTemplatesStatus].includes('loading')}
          options={templateOptions.map(template => ({
            key: template.id,
            label: `${template.type === 'admin' ? '(Admin) ' : ''}${template.label}`,
            value: template.id,
          }))}
          onChange={e => setSelectedTemplateId(e)}
          value={selectedTemplateId}
          notFoundContent='No templates found.'
          dropdownRender={menu => {
            return (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  htmlType='button'
                  type='link'
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                  onClick={() => setCreateModalOpen(true)}>
                  <PlusOutlined /> New Template
                </Button>
              </>
            )
          }}
        />
        <CreateTemplateModal
          brandId={brandId}
          createModalOpen={createModalOpen}
          setCreateModalOpen={setCreateModalOpen}
          setSelectedTemplateId={setSelectedTemplateId}
        />

        {selectedTemplateId && (
          <div className='message-wrapper'>
            <p className='text'>
              {templateOptions.find(t => t.id === selectedTemplateId)?.template}
            </p>
          </div>
        )}
      </Wrapper>
    </Modal>
  )
}

const Avatar = ({ optIn }) => {
  const { user } = optIn
  const profilePicUrl = getProfilePicUrl(optIn)
  const initials = `${optIn.user.firstName?.charAt(0).toUpperCase()} ${optIn.user.lastName?.charAt(0).toUpperCase()}`

  return (
    <CreatorAvatar
      className='avatar'
      key={optIn.id}
      id={user.id}
      size={30}
      url={profilePicUrl}
      initials={initials}
    />
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .avatars {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: -10px;
      border: 1px solid #fff;
    }
    .label {
      margin-left: 15px;
      font-size: 0.8rem;
      color: ${props => props.theme.crcoGrey};
    }
  }
  .ant-select {
    width: 100%;
  }
  .message-wrapper {
    background: ${props => props.theme.crcoOffWhite};
    color: ${props => props.theme.crcoGrey};
    padding: 20px;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    ${props => props.theme.scrollbar}
    .text {
      margin: 0;
    }
  }
`
