import { LoadingOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }

export const AppliedFilters = ({
  creator,
  audience,
  socapiFilters,
  values,
  setValues,
  submitForm,
  closable, // optional prop to make tags closable
}) => {
  // #region HELPER FUNCTIONS (for formatting text in tags)
  const getAudienceAge = values => {
    const ranges = values.audienceAge.map(range => (range === '65-' ? (range = '65+') : range))

    return `${ranges.map(range => range).join(', ')}`
  }

  const getInterests = InterestIdList => {
    let interests = socapiFilters.interests.interests

    let ans = []

    InterestIdList.forEach(interestId => {
      interests.forEach(interest => {
        if (interest.id === interestId) {
          ans.push(interest.name)
        }
      })
    })

    ans = ans.slice(0, 4)

    let string = ans.join(', ')
    return string
  }

  const getLanguageName = languageCode => {
    let socialDataLanguages = socapiFilters.languages

    let languageName

    socialDataLanguages?.forEach(language => {
      if (languageCode === language.code) {
        languageName = language.name
      }
    })

    return languageName
  }

  const getBrands = brandCodes => {
    let socialDataBrands = socapiFilters.interests.brands

    let ans = []
    brandCodes.forEach(brandCode => {
      socialDataBrands.forEach(brand => {
        if (brandCode === brand.id) {
          ans.push(brand.name)
        }
      })
    })

    let string = ans.join(', ')
    return string
  }

  const getTopicTags = topicTags => {
    let ans = []

    topicTags.forEach(tag => {
      ans.push(tag.value)
    })

    let string = ans.join(', ')
    return string
  }

  const getLocations = locations => {
    let ans = []

    locations.forEach(location => {
      ans.push(location.label)
    })

    let string = ans.join(', ')
    return string
  }

  const getAccountType = accountTypeNum => {
    let accountType = ''
    switch (accountTypeNum) {
      case '2':
        accountType = 'Business'
        break
      case '3':
        accountType = 'Creator'
        break
      default:
        accountType = 'Regular'
        break
    }
    return accountType
  }

  const formatActivatedFrom = val => {
    switch (val) {
      case '1-month':
        return 'Past mth'
      case '3-months':
        return 'Past 3 mths'
      case '6-months':
        return 'Past 6 mths'
      case '1-year':
        return 'Past year'
      default:
        return 'All time'
    }
  }
  // #endregion HELPER FUNCTIONS

  return (
    <Wrapper className={audience && 'audience'}>
      {/* APPLIED CREATOR FILTERS */}
      {creator && (
        <>
          <Tag>
            Platform:
            <span className='tag-values platform-tag'>
              <img src={socialIcons[values.platform]} alt='' />
              {values.platform === 'instagram'
                ? 'Instagram'
                : values.platform === 'youtube'
                  ? 'YouTube'
                  : 'TikTok'}
            </span>
          </Tag>

          {values.withContact && values.withContact.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, withContact: undefined })
                submitForm()
              }}>
              Contact Info:
              <span className='tag-values'>Yes</span>
            </Tag>
          )}

          {values.username && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, username: undefined })
                submitForm()
              }}>
              Username:
              <span className='tag-values'>
                @{values.username} {values.usernameOperator && '(Exact match)'}
              </span>
            </Tag>
          )}

          {(values.creatorFollowersMin || values.creatorFollowersMax) && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  creatorFollowersMin: undefined,
                  creatorFollowersMax: undefined,
                })
                submitForm()
              }}>
              Followers:
              <span className='tag-values'>
                Min {values.creatorFollowersMin?.toLocaleString() || 0}
                {values.creatorFollowersMax &&
                  ` - Max ${values.creatorFollowersMax.toLocaleString()}`}
              </span>
            </Tag>
          )}

          {values.creatorFollowersGrowthValue && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  creatorFollowersGrowthValue: undefined,
                  creatorFollowersGrowthInterval: undefined,
                })
                submitForm()
              }}>
              {values.platform === 'youtube' ? 'Subscriber' : 'Follower'} Growth:
              <span className='tag-values'>{values.creatorFollowersGrowthValue}%</span>
            </Tag>
          )}

          {(values.creatorEngagementRate && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorEngagementRate: undefined })
                submitForm()
              }}>
              Engagement:
              <span className='tag-values'>{values.creatorEngagementRate}%</span>
            </Tag>
          )) ||
            ''}

          {values.lastPosted && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, lastPosted: undefined })
                submitForm()
              }}>
              Last Posted:
              <span className='tag-values'>{values.lastPosted} days ago</span>
            </Tag>
          )}

          {values.creatorLocations && values.creatorLocations.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorLocations: undefined })
                submitForm()
              }}>
              Location:
              <span className='tag-values'>{getLocations(values.creatorLocations)}</span>
            </Tag>
          )}

          {values.creatorGender && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorGender: undefined })
                submitForm()
              }}>
              Gender:
              <span className='tag-values gender-tag'>{values.creatorGender}</span>
            </Tag>
          )}

          {values.creatorAgeRange && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorAgeRange: undefined })
                submitForm()
              }}>
              Age:
              <span className='tag-values'>
                {values.creatorAgeRange[0]}-{values.creatorAgeRange[1]}
              </span>
            </Tag>
          )}

          {values.creatorLanguage && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorLanguage: undefined })
                submitForm()
              }}>
              Language:
              <span className='tag-values'>
                {socapiFilters ? (
                  getLanguageName(values.creatorLanguage)
                ) : (
                  <LoadingOutlined style={{ color: '#999' }} />
                )}
              </span>
            </Tag>
          )}

          {values.creatorBio && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorBio: undefined })
                submitForm()
              }}>
              Bio:
              <span className='tag-values'>{values.creatorBio}</span>
            </Tag>
          )}

          {values.audienceRelevance && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceRelevance: undefined })
                submitForm()
              }}>
              Lookalikes:
              <span className='tag-values'>@{values.audienceRelevance}</span>
            </Tag>
          )}

          {values.creatorBrands && values.creatorBrands.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorBrands: undefined })
                submitForm()
              }}>
              Brand Affinity:
              <span className='tag-values'>
                {socapiFilters ? (
                  getBrands(values.creatorBrands)
                ) : (
                  <LoadingOutlined style={{ color: '#999' }} />
                )}
              </span>
            </Tag>
          )}

          {values.relevance && values.relevance.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, relevance: undefined })
                submitForm()
              }}>
              Topic Tags:
              <span className='tag-values'>{getTopicTags(values.relevance)}</span>
            </Tag>
          )}

          {values.isVerified && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, isVerified: undefined })
                submitForm()
              }}>
              Verified Creator
            </Tag>
          )}

          {values.creatorViewsGrowthValue && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  creatorViewsGrowthValue: undefined,
                  creatorViewsGrowthInterval: undefined,
                })
                submitForm()
              }}>
              Views Growth:
              <span className='tag-values'>{values.creatorViewsGrowthValue}%</span>
            </Tag>
          )}

          {values.hashtags?.length > 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, hashtags: undefined })
                submitForm()
              }}>
              Hashtags:
              <span className='tag-values'>
                {values.hashtags.map(hashtag => hashtag).join(', ')}
              </span>
            </Tag>
          )}

          {values.creatorVisibility && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorVisibility: undefined })
                submitForm()
              }}>
              Account Visibility:
              <span className='tag-values'>
                {parseInt(values.creatorVisibility) === 0 ? 'Public' : 'Private'}
              </span>
            </Tag>
          )}

          {values.creatorLikesGrowthValue && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  creatorLikesGrowthValue: undefined,
                  creatorLikesGrowthInterval: undefined,
                })
                submitForm()
              }}>
              Likes Growth:
              <span className='tag-values'>{values.creatorLikesGrowthValue}%</span>
            </Tag>
          )}

          {values.keywords && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, keywords: undefined })
                submitForm()
              }}>
              Keywords:
              <span className='tag-values'>{values.keywords}</span>
            </Tag>
          )}

          {values.creatorInterests && values.creatorInterests.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorInterests: undefined })
                submitForm()
              }}>
              Niche:
              <span className='tag-values'>
                {socapiFilters ? (
                  getInterests(values.creatorInterests)
                ) : (
                  <LoadingOutlined style={{ color: '#999' }} />
                )}
              </span>
            </Tag>
          )}

          {(values.creatorReelViewsMin >= 0 || values.creatorReelViewsMax >= 0) && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  creatorReelViewsMin: undefined,
                  creatorReelViewsMax: undefined,
                })
                submitForm()
              }}>
              Reel Views:
              <span className='tag-values'>
                Min {values.creatorReelViewsMin || 0}
                {values.creatorReelViewsMax && ` - Max ${values.creatorReelViewsMax}`}
              </span>
            </Tag>
          )}

          {values.hasAds && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, hasAds: undefined })
                submitForm()
              }}>
              Sponsored:
              <span className='tag-values'>{values.hasAds === 0 ? 'No' : 'Yes'}</span>
            </Tag>
          )}

          {values.creatorAccountType && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, creatorAccountType: undefined })
                submitForm()
              }}>
              Account Type:
              <span className='tag-values'>{getAccountType(values.creatorAccountType)}</span>
            </Tag>
          )}

          {values.onCreator && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, onCreator: undefined })
                submitForm()
              }}>
              Creator.co Users:
              <span className='tag-values'>{values.onCreator === 'must' ? 'Yes' : 'No'}</span>
            </Tag>
          )}

          {(values.creatorAvgViewsMin || values.creatorAvgViewsMax) && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  creatorAvgViewsMin: undefined,
                  creatorAvgViewsMax: undefined,
                })
                submitForm()
              }}>
              Average Views:
              <span className='tag-values'>
                Min {values.creatorAvgViewsMin || 0}
                {values.creatorAvgViewsMax && ` - Max ${values.creatorAvgViewsMax}`}
              </span>
            </Tag>
          )}

          {values.registered !== null && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, registered: null })
                submitForm()
              }}>
              {values.registered ? 'Registered' : 'Not registered'}
            </Tag>
          )}

          {values.optInsActivatedFrom && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({
                  ...values,
                  optInsActivatedFrom: null,
                  optInsMinCount: null,
                  optInsMaxCount: null,
                })
                submitForm()
              }}>
              Opt-In Activity:
              <span className='tag-values'>
                {formatActivatedFrom(values.optInsActivatedFrom)} ({values.optInsMinCount || 0} -{' '}
                {values.optInsMaxCount || '∞'})
              </span>
            </Tag>
          )}
        </>
      )}

      {/* APPLIED AUDIENCE FILTERS */}
      {audience && (
        <>
          {values.audienceGender && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceGender: undefined })
                submitForm()
              }}>
              Gender:
              <span className='tag-values gender-tag'>{values.audienceGender}</span>
            </Tag>
          )}

          {values.audienceAge && values.audienceAge.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceAge: undefined })
                submitForm()
              }}>
              Age:<span className='tag-values'>{getAudienceAge(values)}</span>
            </Tag>
          )}

          {values.audienceLanguage && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceLanguage: undefined })
                submitForm()
              }}>
              Language:
              <span className='tag-values'>
                {socapiFilters ? (
                  getLanguageName(values.audienceLanguage)
                ) : (
                  <LoadingOutlined style={{ color: '#999' }} />
                )}
              </span>
            </Tag>
          )}

          {values.audienceLocations && values.audienceLocations.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceLocations: undefined })
                submitForm()
              }}>
              Location:
              <span className='tag-values'>{getLocations(values.audienceLocations)}</span>
            </Tag>
          )}

          {values.audienceEthnicity && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceEthnicity: undefined })
                submitForm()
              }}>
              Ethnicity:
              <span className='tag-values'>
                {values.audienceEthnicity === 'Black'
                  ? 'African'
                  : values.audienceEthnicity === 'White'
                    ? 'Caucasian'
                    : values.audienceEthnicity}
              </span>
            </Tag>
          )}

          {values.audienceInterests && values.audienceInterests.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceInterests: undefined })
                submitForm()
              }}>
              Niche:
              <span className='tag-values'>
                {socapiFilters ? (
                  getInterests(values.audienceInterests)
                ) : (
                  <LoadingOutlined style={{ color: '#999' }} />
                )}
              </span>
            </Tag>
          )}

          {values.audienceBrands && values.audienceBrands.length !== 0 && (
            <Tag
              closable={closable}
              onClose={() => {
                if (!closable) return
                setValues({ ...values, audienceBrands: undefined })
                submitForm()
              }}>
              Audience Brands:
              <span className='tag-values'>
                {socapiFilters ? (
                  getBrands(values.audienceBrands)
                ) : (
                  <LoadingOutlined style={{ color: '#999' }} />
                )}
              </span>
            </Tag>
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  .ant-tag {
    background: #e5f3ff;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    max-width: 300px;
    margin: 0;
    overflow: hidden;
    .platform-tag img {
      height: 20px;
      margin-right: 5px;
    }
    .tag-values {
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
      max-width: 200px;
      margin-left: 5px;
    }
    .gender-tag {
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    span {
      font-family: 'Campton-Light', Verdana, sans-serif;
    }
    .platform-icon {
      img {
        height: 25px;
      }
    }
  }
  &.audience {
    .ant-tag {
      background: #f1ecff;
    }
  }
`
