import { Result, Button, Modal, Select, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { queryCache, useQuery } from 'react-query'
import { Route, Switch, Link } from 'react-router-dom'
import styled from 'styled-components'

import Dashboard from './Dashboard'
import Pricing from './pricing/Pricing'
import { ReactQueryDevtools } from '../../node_modules/react-query-devtools/dist/react-query-devtools.production.min.js'
import LoadingLogo from '../components/general/LoadingLogo'
import { NODE_URL } from '../constants'
import { BrandContext } from '../contexts/BrandContext'
import { UserContext } from '../contexts/UserContext'
import { useLaunchDarkly } from '../custom-hooks/useLaunchDarkly'

const Home = () => {
  useLaunchDarkly()

  const { setBrandId, brandId } = useContext(BrandContext)
  const { isLoggedIn, fetchCurrentUserData, fetchBrandData, setRole } = useContext(UserContext)
  const { status: userStatus, data: userData } = useQuery('current-user', fetchCurrentUserData)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const [selectedBrandId, setSelectedBrandId] = useState(userData?.brandProfiles[0]?.brandId)
  const [pendoInitialized, setPendoInitialized] = useState(false)

  useEffect(() => {
    if (userStatus === 'success' && userData?.id) {
      if (!brandId) {
        setBrandId(
          userData.extraData?.selectedBrand
            ? userData.extraData?.selectedBrand
            : userData.brandProfiles[0]?.brandId
        )
      }
      setRole(userData.role)
    }
  }, [userStatus, userData, brandId, setBrandId, setRole])

  useEffect(() => {
    setSelectedBrandId(userData?.brandProfiles[0]?.brandId)
  }, [userData])

  const plan =
    !brandData?.activeSubscriptions.length > 0 ? 'prospect' : brandData?.activeSubscriptions[0]

  // PENDO.IO INTEGRATION (collects platform usage data for analytics)
  useEffect(() => {
    if (userData && brandId && !pendoInitialized) {
      const brandProfile = userData.brandProfiles?.find(brand => brand.brandId === brandId)
      // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
      // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
      // Call this function in your authentication promise handler or callback when your visitor and account id values are available
      // Please use Strings, Numbers, or Bools for value types.

      // eslint-disable-next-line no-undef
      pendo.initialize({
        // USER
        visitor: {
          id: userData.id, // Required if user is logged in, default creates anonymous ID
          email: userData.email, // Recommended if using Pendo Feedback, or NPS Email (we are using Pendo Feedback)
          full_name: `${userData.firstName} ${userData.lastName}`, // Recommended if using Pendo Feedback
          role: userData.role, // Optional
          subscription: plan,
          // You can add any additional visitor level key-values here, as long as it's not one of the above reserved names.
        },

        // BRAND
        account: {
          id: brandId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          name: brandProfile?.brand?.name, // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional
          subscription: plan,
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      })

      // prevent pendo from initializing multiple times on render
      setPendoInitialized(true)
    }
  }, [userData, pendoInitialized, brandId])

  // SHOPIFY INTEGRATION
  const shop = localStorage.getItem('shop')
  const hmac = localStorage.getItem('hmac')
  const code = localStorage.getItem('code')
  const [shopifyModalVisible, setShopifyModalVisible] = useState(shop && hmac)

  const completeShopifyConnection = async () => {
    await axios
      .post(`${NODE_URL}/brand/${selectedBrandId}/shopify/store/connect`, { shop, code })
      .then(() => {
        message.success('Shopify store connected successfully!')
        queryCache.invalidateQueries('current-user')
      })
      .catch(err => {
        message.error(
          err?.response?.data?.error || 'Sorry, something went wrong. Please try again later.'
        )
      })
      .finally(() => {
        endShopifyConnect()
      })
  }

  const endShopifyConnect = () => {
    setShopifyModalVisible(false)
    localStorage.removeItem('shop')
    localStorage.removeItem('hmac')
    localStorage.removeItem('code')
  }

  return (
    <Wrapper>
      {userStatus === 'success' && !!brandId && !!userData?.brandProfiles.length && (
        <>
          <Switch>
            {!isLoggedIn && (
              <Route path={['/pricing/:planId', '/pricing/']}>
                <Pricing brandId={brandId} />
              </Route>
            )}
            <Route>
              <Dashboard />
            </Route>
          </Switch>
          {userData?.role === 'administrator' && <ReactQueryDevtools initalIsOpen={false} />}
        </>
      )}
      {userStatus === 'success' && userData !== null && !userData?.brandProfiles.length && (
        <div className='dashboard-result'>
          <Result
            status='warning'
            title={`Not a Brand Member`}
            subTitle='You must be a member of a brand to access the dashboard. If you believe this is an error, please contact campaignsupport@creator.co.'
            extra={
              <Button type='primary'>
                <Link to='/logout'>Sign Out</Link>
              </Button>
            }
          />
        </div>
      )}
      {(userStatus === 'loading' || (isLoggedIn && userData !== null)) && (
        <Loading>
          <LoadingLogo />
        </Loading>
      )}
      <Modal
        title='Complete Your Shopify Connection'
        open={shopifyModalVisible}
        okText='Connect My Store'
        onOk={completeShopifyConnection}
        onCancel={endShopifyConnect}
        okButtonProps={{ disabled: !brandId }}>
        <p>
          You&apos;re almost there! Let&apos;s finalize the link to your <strong>{shop}</strong>{' '}
          Shopify store.
        </p>
        {userData?.brandProfiles?.length > 1 && (
          <>
            <p>Select the brand you wish to connect with</p>
            <Select value={selectedBrandId} onChange={value => setSelectedBrandId(value)}>
              {userData?.brandProfiles?.map(brand => (
                <Select.Option key={brand?.brand?.id} value={brand?.brand?.id}>
                  {brand?.brand?.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .dashboard-result {
    background: #f6f6f6;
    height: calc(100vh - 50px);
    width: 100%;
    display: grid;
    place-items: center;
    .ant-result {
      max-width: 600px;
    }
  }
`

const Loading = styled.div`
  height: calc(100vh - 50px);
  width: 100%;
  display: grid;
  place-items: center;
`

export default Home
